@import "./assets/fonts/fonts";
@import "./assets/scss/normalize.scss";
@import "./assets/scss/reset.scss";
@import "gerillass";
@import "./assets/scss/variables";

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
span,
li,
a {
  @include antialias;
  cursor: default;
}

html {
  @media screen and (min-width: 1025px) {
    body {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    [data-scroller] {
      height: 100vh;
    }

    .scrollbar-track {
      background: transparent !important;
    }
  }
}

// * {
//   outline: 1px solid red;
//   outline-offset: -1px;
// }

// dev test
// .scrollbar-track-x,
// .scrollbar-thumb-x {
//   @media screen and (min-width: 640px) {
//     display: none;
//   }
// }

#zsiqbtn {
  position: fixed;
  right: 2rem;
  bottom: 1rem;
  transition: 0.4s opacity ease;

  #siqbtndiv {
    box-shadow: none !important;
  }
}
