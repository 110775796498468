@import "gerillass";
@import "./variables";

.textL {
  background: #ffffff;
  padding: 15vh 12vw 20vh 12vw;
  position: relative;
  z-index: 100;

  @include breakpoint(max, $tablet) {
    padding: 7vh 7vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 5vh 5vw 7vh 5vw;
  }

  .text {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 2.7vw;
    font-weight: 400;
    line-height: 140%;
    text-align: left;

    @include breakpoint(max, $tablet) {
      font-size: 4vw;
      text-align: center;
    }

    @include breakpoint(max, $mobile) {
      line-height: 160%;
      font-size: 6vw;
    }

    span {
      color: #0466c8;
      font-family: "AuxMono";
      letter-spacing: -0.2ch;
    }
  }
}
