@import "gerillass";
@import "./variables";

.gridIcons {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5vh 5vw;
  position: relative;
  z-index: 100;

  @include breakpoint(max, $tablet) {
    padding: 5vh 3.5vw;
  }

  @include breakpoint(max, $mobile) {
    flex-direction: column;
    padding: 5vh 5vw;
  }

  .itemWrapper {
    flex: 1;
    margin-bottom: 5vh;
    margin-right: 3vw;
    padding: 5vh 2vw;
    position: relative;
    width: 20vw;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    @include breakpoint(max, $tablet) {
      margin-bottom: 0;
      margin-right: 0;
      padding: 4vw 1vw 3vw 1vw;
    }

    @include breakpoint(max, $mobile) {
      justify-content: center;
      margin-bottom: 5vw;
      min-height: 30vh;
      padding: 5vh 5vw;
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: "";
      @include position(absolute, 0 0 0 0);
      @include sizer(100%);
      border: 1px solid #b9303f;
      opacity: 0.4;
    }

    &.verticalTitle {
      .title {
        flex-direction: column;

        .iconWrapper {
          @include sizer(5vw);
          margin-bottom: 1vw;

          @include breakpoint(max, $tablet) {
            @include sizer(7.5vw);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(20vw);
            margin-bottom: 5vw;
          }
        }

        .text {
          text-align: center;
        }
      }
    }

    .title {
      margin-bottom: 3vh;

      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $tablet) {
        margin-bottom: 1vw;
      }

      @include breakpoint(max, $tablet) {
        margin-bottom: 3vw;
      }

      .iconWrapper {
        @include sizer(3vw);
        flex-shrink: 0;

        @include breakpoint(max, $tablet) {
          @include sizer(5vw);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(10vw);
        }

        .icon {
          @include sizer(100%);
          display: block;
          object-fit: contain;
        }
      }

      .text {
        color: #000000;
        font-family: AuxMono;
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.2ch;
        margin-left: 1rem;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          margin-left: 0.75rem;
        }

        @include breakpoint(max, $mobile) {
          font-size: 5.5vw;
        }
      }
    }

    .itemText {
      color: #999999;
      font-family: Helonik;
      font-size: 1rem;
      font-weight: 400;
      line-height: 180%;
      text-align: center;

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
      }
    }
  }

  &.vertical {
    .itemWrapper {
      &::after {
        display: none;
      }

      @include breakpoint(max, $mobile) {
        @include sizer(100%, auto);
        flex-direction: column;
      }

      .title {
        flex-direction: column;
        justify-content: flex-start;

        .iconWrapper {
          @include sizer(5vw);
          margin-bottom: 2rem;

          @include breakpoint(max, $tablet) {
            @include sizer(7.5vw);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(20vw);
          }
        }

        .text {
          font-family: Helonik;
          font-size: 1.5vw;
          letter-spacing: 0;
          margin-left: 0;
          text-align: center;

          @include breakpoint(max, $tablet) {
            font-size: 2.5vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 7vw;
            margin-bottom: 3vh;
          }
        }
      }
    }
  }
}
