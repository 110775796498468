@import "gerillass";
@import "../scss/variables";

.about {
  overflow: hidden;

  .hero {
    padding: 15vh 7vw;
    position: relative;

    @include breakpoint(max, $tablet) {
      padding: 7vh 5vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 5vh 5vw;
    }

    .sphereWrapper {
      @include position(absolute, 30% -10% auto auto);
      @include sizer(28vw);

      @include breakpoint(max, $mobile) {
        display: none;
      }

      .img {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .breadcrumb {
      border: 1px solid #0466c8;
      border-radius: 32px;
      color: #0466c8;
      display: block;
      font-family: AuxMono;
      letter-spacing: -0.2ch;
      line-height: 100%;
      margin-bottom: 7vh;
      font-size: 1.1vw;
      font-weight: 300;
      text-align: left;
      padding: 0.5rem 2.5rem;
      width: max-content;

      @include breakpoint(max, $tablet) {
        font-size: 2vw;
        margin-bottom: 5vh;
      }

      @include breakpoint(max, $mobile) {
        font-size: 3.5vw;
        margin-bottom: 3vh;
        padding: 0;
        border: none;
      }
    }

    .title {
      color: #1a1818;
      font-family: Helonik;
      font-size: 7vw;
      font-weight: 700;
      line-height: 100%;
      text-align: left;

      @include breakpoint(max, $tablet) {
        font-size: 8vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 12vw;
        margin-bottom: 5vh;
        padding: 0;
        border: none;
      }

      span {
        display: inline-block;
        color: #0466c8;
        font-family: AuxMono;
        font-size: 8vw;
        font-weight: 400;
        letter-spacing: -0.25ch;
        transform: translateX(-0.125ch);

        @include breakpoint(max, $tablet) {
          font-size: 9vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 13vw;
        }
      }
    }

    .bottom {
      margin-top: 17vh;

      display: flex;
      align-items: flex-start;

      @include breakpoint(max, $tablet) {
        margin-top: 20vh;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
        margin-top: 15vh;
      }

      .imgWrapper {
        @include sizer(45vw, 55vh);
        overflow: hidden;

        @include breakpoint(max, $tablet) {
          @include sizer(55vw, 27vh);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(100vw, 40vh);
          margin-top: 10vh;
          margin-left: -5vw;
        }

        .img {
          @include sizer(100%);
          display: block;
          object-fit: cover;
        }
      }

      .right {
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        transform: translateY(-7vh);

        @include breakpoint(max, $mobile) {
          transform: translateY(0);
        }

        .greeting {
          color: #1a1818;
          font-family: Helonik;
          font-size: 1.25vw;
          font-style: italic;
          font-weight: 400;
          line-height: 160%;
          margin-bottom: 17vh;
          text-align: left;
          width: 25vw;

          @include breakpoint(max, $tablet) {
            font-size: 1.8vw;
            margin-bottom: 10vh;
            margin-left: 5vw;
            width: 30vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 4.5vw;
            font-weight: 400;

            margin-left: 0;
            width: 100%;
          }
        }

        .logo {
          @include sizer(12vw, auto);
          display: block;
          object-fit: cover;

          @include breakpoint(max, $tablet) {
            @include sizer(15vw, auto);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(50vw, auto);
          }
        }
      }
    }
  }

  .mContainer {
    width: 100vw;
    overflow: hidden;

    @include breakpoint(max, $tablet) {
      display: none;
    }

    .marqueeText {
      color: #1a1818;
      font-family: Helonik;
      font-size: 85px;
      font-weight: 700;
      line-height: 100%;
      will-change: transform;

      @include breakpoint(max, $tablet) {
        font-size: 4vw;
        line-height: 140%;
        padding: 5vw 0;

        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      @include breakpoint(max, $mobile) {
        font-size: 10vw;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .part {
        display: inline-block;
        margin-left: 3vw;

        // @include breakpoint(max, $tablet) {
        //   display: flex;
        //   align-items: center;
        //   flex-direction: column;
        //   justify-content: center;
        //   text-align: center;
        //   width: 30vw;
        // }

        // @include breakpoint(max, $mobile) {
        //   width: 100%;
        // }

        .large {
          font-size: 2ch;
          padding-left: 2vw;
          padding-right: 2vw;

          @include breakpoint(max, $tablet) {
            font-size: 3ch;
            margin-top: 5vw;
            margin-bottom: 4vw;
            padding: 0;
          }

          @include breakpoint(max, $mobile) {
            font-size: 2ch;
            margin-top: 15vw;
            margin-bottom: 0vw;
          }

          .percentage {
            font-size: 1ch;
          }
        }

        .comma {
          @include breakpoint(max, $tablet) {
            display: none;
          }
        }
      }
    }
  }

  .content {
    background: #ffffff;
    padding: 5vh 7vw 15vh 7vw;
    z-index: 50000000;
    position: relative;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    @include breakpoint(max, $tablet) {
      padding: 0vh 5vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 5vh 5vw 0 5vw;
    }

    .top {
      display: flex;

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
      }

      .left {
        flex: 1;

        .textWrapper {
          margin-bottom: 15vh;
          margin-top: 10vh;

          @include breakpoint(max, $tablet) {
            margin-bottom: 15vh;
          }

          @include breakpoint(max, $mobile) {
            margin-bottom: 5vh;
            margin-top: 5vh;
          }

          .text {
            color: #1a1818;
            font-family: Helonik;
            font-size: 3vw;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 5vh;
            text-align: left;
            width: 50vw;

            @include breakpoint(max, $tablet) {
              font-size: 4vw;
              line-height: 140%;
              width: 53vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 6.5vw;
              font-weight: 300;
              line-height: 140%;
              margin-bottom: 0;
              text-align: center;
              width: 100%;
            }

            span {
              color: #0466c8;
              font-family: AuxMono;
              letter-spacing: -0.25ch;
              transform: translateX(-0.125ch);
            }
          }
        }

        .btnWrapper {
          width: max-content;

          @include breakpoint(max, $mobile) {
            margin: 0 auto;
            margin-top: 5vh;
          }
        }

        .imgWrapper {
          @include sizer(50vw, 60vh);
          overflow: hidden;

          @include breakpoint(max, $tablet) {
            @include sizer(50vw, 25vh);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(100vw, 40vh);
            margin-left: -5vw;
          }

          .img {
            @include sizer(100%);
            display: block;
            object-fit: cover;
          }
        }
      }

      .right {
        flex: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(max, $tablet) {
          margin-top: 27vh;
        }

        @include breakpoint(max, $tablet) {
          margin-top: 0;
        }

        .par {
          color: #1a1818;
          font-family: Helonik;
          font-size: 1.25vw;
          font-weight: 400;
          line-height: 160%;
          margin-bottom: 15vh;
          text-align: left;
          width: 20vw;

          @include breakpoint(max, $tablet) {
            font-size: 1.9vw;
            line-height: 180%;
            margin-bottom: 3vh;
            margin-left: 3vw;
            width: 35vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 4.5vw;
            font-weight: 300;
            line-height: 180%;
            margin-bottom: 2.5vh;
            margin-left: 0;
            text-align: center;
            width: 100%;
          }

          strong {
            font-weight: 700;

            @include breakpoint(max, $mobile) {
              font-weight: 500;
            }
          }
        }
      }
    }

    .bottom {
      .textWrapper {
        padding: 10vh 0;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(max, $tablet) {
          padding: 5vh 0;
        }

        @include breakpoint(max, $mobile) {
          padding: 0;
        }

        .squareWrapper {
          @include sizer(7vw);
          margin: 5vh 0;

          @include breakpoint(max, $tablet) {
            @include sizer(15vw);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(30vw);
          }

          .img {
            @include sizer(100%, auto);
            display: block;
            object-fit: cover;
          }
        }

        .text {
          color: #1a1818;
          font-family: Helonik;
          font-size: 3vw;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 5vh;
          text-align: center;
          width: 70vw;

          @include breakpoint(max, $tablet) {
            font-size: 4vw;
            line-height: 140%;
            width: 80vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 6.5vw;
            line-height: 140%;
            width: 100%;
          }

          span {
            display: inline-block;
            color: #0466c8;
            font-family: AuxMono;
            letter-spacing: -0.2ch;
            transform: translateX(-0.125ch);
          }
        }
      }

      .references {
        padding: 5vh 2.5vw;

        display: flex;
        justify-content: space-between;

        @include breakpoint(max, $tablet) {
          padding: 5vh 0;
          flex-direction: column;
        }

        @include breakpoint(max, $mobile) {
          flex-direction: column-reverse;
          padding-top: 0;
        }

        .referenceLogos {
          .row {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .logoWrapper {
              @include sizer(10vw, 7vh);
              margin-bottom: 4vw;
              margin-right: 2vw;
              overflow: hidden;
              position: relative;

              display: flex;
              align-items: center;
              justify-content: center;

              @include breakpoint(max, $tablet) {
                @include sizer(20vw, 5vh);
              }

              &:last-child {
                margin-right: 0;
              }

              &::after {
                content: "";
                @include position(absolute, 0 0 0 0);
                @include sizer(100%);
                border: 1px solid #cae9ff;
                border-radius: 80px;
                transition: 0.4s all ease;
                pointer-events: none;
              }

              &:hover {
                &::after {
                  transform: scale(0.9);
                  border: 2px solid #00509d;
                }

                .companyLogo {
                  transform: scale(0.9);
                }
              }

              .companyLogo {
                max-height: 50%;
                max-width: 70%;
                transition: 0.4s all ease;

                @include breakpoint(max, $tablet) {
                  max-height: 50%;
                  max-width: 60%;
                }
              }
            }
          }
        }

        .par {
          color: #1a1818;
          font-family: Helonik;
          font-size: 1.25vw;
          font-weight: 400;
          line-height: 180%;
          text-align: left;
          transform: translateY(-20%);
          width: 25vw;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
            line-height: 200%;
            margin-top: 7vh;
            width: 60vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 4.5vw;
            font-weight: 300;
            line-height: 180%;
            margin-top: 7vh;
            text-align: center;
            width: 100%;
          }

          strong {
            font-weight: 700;

            @include breakpoint(max, $mobile) {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
