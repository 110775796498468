@import "gerillass";
@import "./variables";

.blogDetail {
  background: #ffffff;
  overflow: hidden;
  padding: 20vh 20vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(max, $tablet) {
    padding: 10vh 5vw;
  }

  @include breakpoint(max, $mobile) {
  }

  .navBtn {
    border-bottom: 1px solid #000000;
    padding: 0.5rem 0;

    &.prev {
      @include position(fixed, 20vh auto auto 5vw);
      cursor: pointer;

      * {
        cursor: pointer;
      }

      @include breakpoint(max, $tablet) {
        @include position(relative, auto);
        margin-right: auto;
        transform: translateY(-200%);
      }

      @include breakpoint(max, $mobile) {
        transform: translateY(-150%);
      }

      .link {
        .iconWrapper {
          transform: rotate(180deg);
        }

        .text {
          margin-left: 1rem;
        }
      }
    }

    &.next {
      cursor: pointer;
      display: inline-block;
      position: relative;
      margin-left: auto;
      margin-top: 5vh;

      @include breakpoint(max, $mobile) {
        margin: 0 auto;
      }

      * {
        cursor: pointer;
      }

      .link {
        .text {
          margin-right: 1rem;
        }
      }
    }

    .link {
      display: flex;
      align-items: center;

      .iconWrapper {
        @include sizer(10px, auto);

        @include breakpoint(max, $mobile) {
          @include sizer(8px, auto);
        }
      }

      .text {
        color: #1a1818;
        display: block;
        font-family: "Helonik";
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 100%;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 1.75rem;
        }

        @include breakpoint(max, $mobile) {
          font-size: 1rem;
        }
      }
    }
  }

  .tags {
    padding: 2rem 0;

    display: flex;
    align-items: center;

    @include breakpoint(max, $mobile) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
    }

    .link {
      border-radius: 32px;
      border: 1px solid #000;
      color: #000;
      cursor: pointer;
      font-family: Helonik;
      font-size: 1rem;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 3vw;
      margin-right: 1rem;
      opacity: 0.2;
      padding: 1rem 2rem;
      text-align: center;
      transition: 0.4s all ease;

      @include breakpoint(max, $mobile) {
        font-size: 0.75rem;
        margin-bottom: 5vw;
        padding: 0.5rem 1rem;
      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .title {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 4vw;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 2vw;
    text-align: center;

    @include breakpoint(max, $tablet) {
      font-size: 6vw;
      line-height: 130%;
    }

    @include breakpoint(max, $mobile) {
      font-size: 8vw;
      margin-bottom: 10vw;
    }
  }

  .imgWrapper {
    @include sizer(100%, auto);
    margin-bottom: 10vh;

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
    }
  }
}

.text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 3vw;
    font-weight: 700;
    line-height: 100%;
    text-align: center;

    @include breakpoint(max, $tablet) {
      font-size: 6vw;
      line-height: 140%;
    }

    @include breakpoint(max, $mobile) {
      font-size: 6vw;
      width: 100vw;
      padding: 5vw;
    }
  }

  p {
    margin-bottom: 5vh;
    text-align: center;
    color: #1a1818;
    font-family: "Helonik";
    font-size: 1.8vw;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 7vh;

    @include breakpoint(max, $tablet) {
      font-size: 3vw;
      margin-bottom: 5vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 4vw;
      line-height: 200%;
      width: 100vw;
      padding: 0 5vw;
      margin-bottom: 5vw;
    }
  }

  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
