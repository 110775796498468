@import "gerillass";
@import "./variables";

.hero {
  padding: 15vh 5vw 10vh 5vw;
  position: relative;
  z-index: 101;

  display: flex;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  .imgWrapper {
    @include sizer(55vw, 65vh);
    margin-left: auto;
    overflow: hidden;

    @include breakpoint(max, $tablet) {
      @include sizer(100vw, 40vh);
    }

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
    }
  }

  .iconWrapper {
    position: absolute;
    margin-left: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $mobile) {
      display: none;
    }

    &.rect {
      @include sizer(22vw, 27vh);
      left: 25%;
      top: 55%;
      overflow: hidden;
      border-radius: 10px;

      background: rgba(180, 20, 38, 0.8);
      border-radius: 10px;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);

      @include breakpoint(max, $tablet) {
        @include sizer(35vw, 17vh);
        left: 2%;
        top: 70%;
      }

      .icon {
        @include sizer(90%);
        display: block;
        object-fit: contain;
        z-index: 100;
      }
    }

    &.lock {
      @include sizer(9vw);
      right: 15%;
      top: 80%;

      background: rgba(180, 20, 38, 0.8);
      border-radius: 50%;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);

      @include breakpoint(max, $tablet) {
        @include sizer(15vw);
        left: 98%;
        top: 80%;
      }

      .icon {
        @include sizer(60%);
        display: block;
        object-fit: contain;
        z-index: 100;
      }
    }

    &.bar {
      @include sizer(30vw, auto);
      top: 30%;

      @include breakpoint(max, $tablet) {
        @include sizer(40vw, auto);

        left: 58%;
        top: 20%;
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: contain;
        z-index: 100;
      }
    }
  }
}

.detail {
  padding: 5vh 10vw 5vh 7vw;

  @include breakpoint(max, $tablet) {
    padding: 0 5vw;
  }

  .textL {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 2vw;
    font-weight: 400;
    line-height: 160%;
    margin: 10vh 0;
    text-align: left;
    width: 50vw;

    @include breakpoint(max, $tablet) {
      @include sizer(100%);
      font-size: 3.5vw;
      text-align: center;
    }

    @include breakpoint(max, $mobile) {
      font-size: 5vw;
    }
  }

  .imgWithText {
    margin: 15vh 0 0 0;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      flex-direction: column;
    }

    @include breakpoint(max, $mobile) {
      margin-bottom: 5vh;
    }

    .imgWrapper {
      @include sizer(45vw, auto);
      margin-top: 20vh;
      overflow: hidden;

      @include breakpoint(max, $tablet) {
        @include sizer(100%, auto);
        margin-top: 0;
        margin-bottom: 10vh;
      }

      .img {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .textWrapper {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 160%;
      text-align: left;
      width: 25vw;

      @include breakpoint(max, $tablet) {
        font-size: 1.75rem;
        line-height: 200%;
        text-align: center;
        width: 100%;
      }

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
      }

      .textS {
        margin-bottom: 5vh;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
