@import "gerillass";
@import "./_variables.scss";

.revealWrapper {
  position: relative;
  will-change: transform;
  z-index: -1;

  .overlay {
    @include position(absolute, 0 0 0 0);
    @include sizer(100%);
    background: #000000;
    z-index: 1000;

    @include breakpoint(max, $tablet) {
      display: none;
    }
  }
}
