@import "gerillass";
@import "./_variables";

.dropdown {
  position: relative;
  @include sizer(100%);

  &.contactPage {
    .dropdownHeader {
      .headerText {
        font-size: 1.25vw;

        @media (max-width: $tablet) {
          font-size: 2.5vw;
        }

        @media (max-width: $mobile) {
          font-size: 7vw;
        }
      }
    }
  }

  .dropdownHeader {
    @include sizer(100%);

    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    border-bottom: 1px solid #000000;
    background: transparent;

    * {
      cursor: pointer;
    }

    .headerText {
      position: relative;
      outline: none;
      white-space: nowrap;
      margin: 0;
      padding: 0;
      flex-grow: 1;
      overflow: hidden;

      color: #00509d;
      font-family: AuxMono;
      font-size: 1.75vw;
      font-weight: 500;
      letter-spacing: -0.2ch;
      line-height: 125%;

      @media (max-width: $mobile) {
        font-size: 7vw;
        text-align: center;
      }
    }

    .headerIcon {
      width: 1rem;
    }
  }

  .dropdownBody {
    border-radius: 8px;
    cursor: pointer;
    font-family: Helonik;
    margin-top: 1%;
    overflow: hidden;
    padding: 0.5rem 0;
    position: absolute;
    transition: 0.4s all ease;
    min-width: 100%;
    z-index: 10;

    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    &.open {
      display: block;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;
      background: rgba(#000000, 0.9);
    }

    .dropdownItem {
      margin: 5px;
      padding: 0.5rem 1.25rem;
      z-index: 10;
      color: #f2f2f2;
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      font-family: Helonik;
      font-size: 1.25rem;
      font-weight: 300;
      white-space: nowrap;

      @media (max-width: $mobile) {
        font-size: 1rem;
      }

      &:hover {
        background: #f2f2f236;
      }

      &.defaultOption {
        opacity: 0.5;
      }
    }
  }
}
