@import "gerillass";
@import "./_variables.scss";

.fixedWrapper {
  @include position(fixed, 0 0 0 0);
  @include sizer(100vw, 100vh);
  pointer-events: none;
  z-index: 10000;

  .stickyNavWrapper {
    @include position(absolute, auto auto 1vw 1vw);
    pointer-events: auto;
    width: 35vw;
    z-index: 2000;

    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    @include breakpoint(max, $tablet) {
      @include position(absolute, auto auto 5vw 1vw);
      width: 60vw;
    }

    @include breakpoint(max, $mobile) {
      @include position(absolute, auto 0 0 0);
      width: 100%;
    }

    .stickyNav {
      background: #00509d;
      border-radius: 64px;
      cursor: pointer;
      display: inline-block;
      padding: 1.25rem;
      pointer-events: auto;
      z-index: 5000;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include breakpoint(max, $mobile) {
        border-radius: 0;
        padding: 1rem;
      }

      * {
        cursor: pointer;
      }

      .oWrapper {
        overflow: hidden;

        &.navTitle {
          margin-bottom: 5vh;
        }
      }

      .title {
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        .arrow {
          border: 1px solid #ffffff;
          border-radius: 50%;
          margin-right: 1.5rem;

          @include breakpoint(max, $mobile) {
            margin-right: 0.5rem;
          }

          .iconWrapper {
            @include sizer(16px);
            margin: 0.5rem;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .textCollapsed,
        .textExpand {
          color: #ffffff;
          font-family: AuxMono;
          font-size: 1vw;
          font-weight: 400;
          letter-spacing: -0.2ch;
          line-height: 100%;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 3.5vw;
            line-height: 140%;
          }
        }
      }
    }

    .navigation {
      @include position(absolute, auto auto 110% 0);
      @include sizer(100%, auto);
      background: #00509d;
      border-radius: 16px;
      padding-bottom: 5vh;
      padding: 1.5vw 2vw;
      z-index: 4000;

      @include breakpoint(max, $tablet) {
        padding: 3vw 2.5vw;
      }

      @include breakpoint(max, $mobile) {
        @include position(absolute, auto auto 100% 0);
        border-radius: 0;
        padding: 5vw 2.5vw;
      }

      .navTitle {
        color: #ffffff;
        font-family: Helonik;
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 140%;
        width: 12em;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
          margin-bottom: 3vh;
        }

        @include breakpoint(max, $mobile) {
          font-size: 6vw;
          margin-bottom: 3vh;
        }
      }

      .navItems {
        .navItem {
          cursor: pointer;
          padding-top: 0.5rem;
          position: relative;
          width: 100%;

          display: flex;
          align-items: center;

          * {
            cursor: pointer;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          &::after {
            content: "";
            @include position(absolute, auto 0 0 0);
            @include sizer(100%, 1px);
            background: #ffffff;
            opacity: 0.1;
          }

          .index {
            color: #ffffff;
            font-family: "AuxMono";
            font-size: 1vw;
            font-weight: 400;
            letter-spacing: -0.2ch;
            line-height: 100%;
            margin-right: 2rem;

            @include breakpoint(max, $tablet) {
              font-size: 2vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 4vw;
              margin-bottom: 0vh;
            }
          }

          .link {
            color: #ffffff;
            font-family: "Helonik";
            font-size: 1vw;
            font-weight: 400;
            line-height: 100%;
            transition: 0.3s all ease;

            @include breakpoint(max, $tablet) {
              font-size: 2vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 3.5vw;
              margin-bottom: 0vh;
            }
          }

          .arrow {
            @include sizer(50px);
            padding: 1rem;
            margin-left: auto;
            transform: rotate(-45deg);
            transition: 0.4s all ease;
          }

          &:hover {
            .link {
              transform: translateX(1rem);
            }

            .arrow {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }
}
