@import "gerillass";
@import "../scss/variables";

.marquee {
  margin: 2rem 0;

  .marqueeInner {
    display: flex;
    will-change: transform;

    .marqueeItem {
      display: inline-block;
      flex-shrink: 0;
      padding: 0 4px;
    }
  }
}
