@import "gerillass";
@import "../scss/variables";

.featureCards {
  background: #ffffff;
  padding: 10vh 12vw 0 12vw;
  position: relative;
  z-index: 50;

  @include breakpoint(max, $tablet) {
    padding: 10vh 6vw 10vh 6vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 7vh 0;
  }

  .greeting {
    margin-left: auto;
    width: max-content;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
      margin-right: 5vw;
    }

    .iconWrapper {
      @include sizer(5vw);
      margin-right: 2rem;

      @include breakpoint(max, $tablet) {
        @include sizer(7vw);
      }

      @include breakpoint(max, $mobile) {
        @include sizer(25vw);
        margin-right: 0;
        margin-bottom: 5vh;
        margin-top: 3vh;
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: cover;
        transform: rotate(90deg);
      }
    }

    .greetingText {
      color: #000000;
      font-family: "Helonik";
      font-size: 2.7vw;
      font-weight: 400;
      line-height: 140%;
      text-align: left;
      width: 30vw;

      @include breakpoint(max, $tablet) {
        font-size: 5vw;
        text-align: right;
        width: 52vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 7vw;
        width: 80vw;
      }
    }
  }

  .featureCard {
    @include sizer(27vw, 80vh);
    background: #002855;
    border-radius: 10px;
    padding: 1.5rem 2rem;
    position: relative;

    @include breakpoint(min, $tablet) {
      &:hover {
        .textWrapper {
          .title {
            transform: translateY(0);
          }
        }
      }
    }

    @include breakpoint(max, $tablet) {
      @include sizer(42vw, 49vh);
      padding: 1.5rem 1.5rem;
    }

    @include breakpoint(max, $mobile) {
      @include sizer(90vw, 70vh);
      margin-bottom: 5vh;
      margin-left: 5vw;
      margin-right: 5vw;
    }

    &:nth-child(even) {
      margin-top: 20vh;

      @include breakpoint(max, $tablet) {
        margin-top: 10vh;
      }
    }

    &:nth-child(odd) {
      margin-top: -20vh;
    }

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 40vh;

      @include breakpoint(max, $tablet) {
        margin-top: 30vh;
      }
    }

    &.open {
      .textWrapper {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

        .title {
          transform: translateY(0);
        }

        .text {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }

      .iconsWrapper {
        .iconWrapper {
          .plus {
            opacity: 0;
            visibility: hidden;
            transform: rotate(-180deg);
            scale: 0;
          }

          .minus {
            opacity: 1;
            visibility: visible;
            transform: rotate(-180deg);
            scale: 1;
          }
        }
      }
    }

    .bg {
      @include position(absolute, 0 0 0 0);
      @include sizer(100%);
      mix-blend-mode: color-burn;
      object-fit: cover;
      opacity: 0.8;
    }

    .index {
      color: #1365b5;
      display: block;
      font-family: "AuxMono";
      font-size: 5vw;
      font-weight: 400;
      letter-spacing: -0.2ch;
      line-height: 100%;
      text-align: left;
      transform: translateX(-0.2ch);

      @include breakpoint(max, $tablet) {
        font-size: 8vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 15vw;
        margin-bottom: 10vh;
      }
    }

    .textWrapper {
      color: #ffffff;
      font-family: "Helonik";
      margin-top: 10vh;
      text-align: left;
      transition: 0.3s all ease-in-out;

      @include breakpoint(max, $tablet) {
        margin-top: 20%;
      }

      .title {
        font-size: 2vw;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 5vh;
        transition: 0.4s all ease;
        transform: translateY(25vh);
        transform-origin: bottom center;
        width: 95%;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
          transform: translateY(0);
          width: 90%;
        }

        @include breakpoint(max, $mobile) {
          font-size: 6.5vw;
          margin-bottom: 5vh;
        }
      }

      .text {
        font-size: 1.2vw;
        font-weight: 300;
        line-height: 140%;
        opacity: 0;
        transition: 0.3s all ease;
        transform: translateY(100px);
        transform-origin: bottom center;
        visibility: hidden;
        width: 90%;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          line-height: 180%;
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          width: 100%;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
        }
      }
    }

    .iconsWrapper {
      @include position(absolute, auto 1.75rem 1.5rem auto);
      @include sizer(50px);

      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $tablet) {
        display: none;
      }

      .iconWrapper {
        @include sizer(100%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .icon {
          @include sizer(100%);
          display: block;
          transition: 0.4s all ease;
        }
      }

      .plus {
        will-change: transform;
      }

      .minus {
        will-change: transform;
      }
    }
  }

  .cardsWrapper {
    padding: 15vh 5vw;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      padding: 10vh 0 0 0;
    }
  }

  .featureCardsMobile {
    padding: 5vh 0;
  }
}
