@import "gerillass";
@import "./_variables";

.singleMedia {
  padding: 10vh 5vw;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  .mediaWrapper {
    @include sizer(60vw, auto);

    @include breakpoint(max, $tablet) {
      @include sizer(100%, 40vw);
    }

    @include breakpoint(max, $mobile) {
      @include sizer(100%, auto);
    }

    .videoWrapper {
      @include sizer(100%);
      overflow: hidden;
      position: relative;
      z-index: 100;

      .thumb {
        @include position(absolute, 0 0 0 0);
        background-image: url(../img/video-thumb.png);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        object-fit: cover;
        z-index: 20;

        &:hover {
          background-size: 110%;

          .play {
            opacity: 1;
          }
        }

        .play {
          @include position(absolute, 50% 0 0 50%);
          transform: translate(-50%, -50%);
          transition: 0.4s all ease;
          opacity: 0.5;
          cursor: pointer;

          @include breakpoint(max, $mobile) {
            @include sizer(20vw);
          }
        }
      }

      .video {
        @include sizer(100%);
        display: block;
        object-fit: cover;
        z-index: 10;
      }
    }

    .imgWrapper {
      @include sizer(100%);

      .img {
        @include sizer(100%);
        display: block;
        object-fit: contain;
      }
    }
  }
}
