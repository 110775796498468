@import "gerillass";
@import "./_variables";

.modal {
  @include position(fixed, 0 0 0 0);
  @include sizer(100vw, 100vh);

  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    content: "";
    @include position(absolute, 0 0 0 0);
    @include sizer(100%);
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    z-index: -1;
  }

  .content {
    max-width: 90vw;
    max-height: 90vh;
  }

  .close {
    @include position(absolute, 3vw 3vw auto auto);
    @include sizer(3vw);
    cursor: pointer;

    @include breakpoint(max, $tablet) {
      @include sizer(5vw);
    }

    @include breakpoint(max, $mobile) {
      @include sizer(10vw);
    }
  }
}
