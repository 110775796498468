@import "gerillass";
@import "./variables";

.titleAndText {
  background: #ffffff;
  padding: 15vh 5vw 10vh 5vw;
  position: relative;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 7vh 5vw;
  }

  .textWrapper {
    width: 60vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $tablet) {
      width: 80vw;
    }

    @include breakpoint(max, $tablet) {
      width: 100%;
    }

    .small {
      display: block;
      font-family: Helonik;
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 2vh;
      text-align: center;

      @include breakpoint(max, $tablet) {
        font-size: 2.25vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
        line-height: 160%;
        margin-bottom: 10vw;
      }
    }

    .title {
      font-family: Helonik;
      font-size: 3vw;
      font-weight: 400;
      letter-spacing: 0.01ch;
      line-height: 140%;
      margin-bottom: 5vh;
      text-align: center;

      @include breakpoint(max, $tablet) {
        font-size: 4.5vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
        line-height: 180%;
        margin-bottom: 5vw;
      }
    }

    .text {
      font-family: Helonik;
      font-size: 1.35rem;
      font-weight: 400;
      line-height: 160%;
      text-align: center;
      width: 70%;

      @include breakpoint(max, $tablet) {
        font-size: 1.5rem;
      }

      @include breakpoint(max, $mobile) {
        font-size: 1rem;
        line-height: 180%;
        width: 100%;
      }
    }
  }

  .btnWrapper {
    margin-top: 5vw;

    @include breakpoint(max, $tablet) {
      margin-top: 7vw;
    }

    @include breakpoint(max, $mobile) {
      margin-top: 10vw;
    }
  }
}
