@import "gerillass";
@import "./_variables";

.slider {
  padding-top: 10vh;
  position: relative;
  z-index: 500;

  display: grid;
  grid-template-columns: 20vw 1fr;

  @include breakpoint(max, $tablet) {
    grid-template-columns: 30vw 1fr;
    padding-top: 5vh;
  }

  @include breakpoint(max, $mobile) {
    grid-template-rows: 1fr 20vh;
    grid-template-columns: none;
    padding-top: 0;
  }

  .bgVideo {
    @include position(absolute, 0 0 0 0);
    @include sizer(100vw, 150vh);
    z-index: -1;

    .video {
      @include sizer(100%);
      object-fit: cover;
      transform: rotate(180deg);
      filter: contrast(135%);
      opacity: 0.1;
    }
  }

  .nav {
    z-index: 500;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @include breakpoint(max, $mobile) {
      display: none;
    }

    .itemWrapper {
      margin-top: 13.75vh;

      display: flex;
      align-items: flex-start;

      @include breakpoint(max, $tablet) {
        margin-top: 6.5vh;
      }

      .navItems {
        position: relative;

        flex: 1;

        .bg {
          @include position(absolute, 0 0 0 0);
          background: #e8e7e7;
          transition: 0.8s all ease-in-out;
          width: 100%;
        }

        .navItem {
          cursor: pointer;
          padding: 1.5rem 1.25rem 1.5rem 2vw;

          display: flex;
          position: relative;

          @include breakpoint(max, $tabletMini) {
            padding: 1rem;
          }

          &::after {
            content: "";
            @include position(absolute, auto 0 0 0);
            @include sizer(100%, 1px);
            background: #1a1818;
            opacity: 0.1;
            will-change: transform;
          }

          .text {
            cursor: pointer;
            color: #1a1818;
            font-family: "Helonik";
            font-size: 1vw;
            font-weight: 400;
            line-height: 120%;
            transition: 1s all ease-in-out;
            will-change: transform;

            @include breakpoint(max, $tablet) {
              font-size: 1.5vw;
            }

            .itemIndex {
              cursor: pointer;
              font-family: "AuxMono";
              font-size: 0.75vw;
              letter-spacing: -0.1vw;
              padding-right: 1rem;

              @include breakpoint(max, $tablet) {
                font-size: 1.25vw;
              }
            }
          }

          .arrow {
            @include sizer(1vw);

            cursor: pointer;
            margin-left: auto;
            transition: 1s all ease-in-out;
            will-change: transform;
          }
        }
      }
    }
  }

  .content {
    margin-left: 5vw;
    z-index: 500;

    display: grid;
    grid-template-rows: 1fr 65vh;

    @include breakpoint(max, $tablet) {
      grid-template-rows: 1fr 30vh;
      margin-left: 1vw;
    }

    @include breakpoint(max, $mobile) {
      @include sizer(100vw, 100%);
      grid-template-rows: 40vh 1fr;
      grid-row: 1;
      margin-left: 0;
    }

    .top {
      margin-bottom: 2.5vw;
      padding-right: 5vw;

      display: flex;
      align-items: flex-end;

      @include breakpoint(max, $tablet) {
        align-items: center;
        padding-right: 2.5vw;
      }

      @include breakpoint(max, $mobile) {
        grid-row: 2;
        margin: 0;
        padding: 0;
      }

      .texts {
        position: relative;
        flex: 1;

        .oWrapper {
          height: 5vw;
          overflow: hidden;
          position: relative;

          @include breakpoint(max, $tablet) {
            height: 6vw;
          }

          @include breakpoint(max, $mobile) {
            height: 30vw;
          }
        }

        .text {
          @include position(absolute, 0 auto auto 0);
          @include sizer(100%);
          transition: 1s ease-in-out;

          .subtitle {
            color: #1a1818;
            display: block;
            font-family: "Helonik";
            font-size: 1.25vw;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 0.5rem;
            transition: 1s ease-in-out;
            white-space: nowrap;

            @include breakpoint(max, $mobile) {
              font-size: 0.75rem;
              padding: 2rem 0.75rem 0 0.75rem;
              white-space: normal;
            }
          }

          .title {
            color: #1a1818;
            display: block;
            font-family: "AuxMono";
            font-size: 2.7vw;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.2ch;
            transform: translateX(-0.4vw);
            transition: 1s ease-in-out;
            white-space: nowrap;

            @include breakpoint(max, $mobile) {
              font-size: 1.5rem;
              padding: 0 0.75rem 0.75rem 0.75rem;
              white-space: normal;
            }
          }
        }
      }

      .btnWrapper {
        width: auto;

        @include breakpoint(max, $mobile) {
          display: none;
        }
      }
    }

    .bottom {
      @include sizer(100%, 120%);
      overflow: hidden;
      position: relative;

      @include breakpoint(max, $tablet) {
        @include sizer(100%, 110%);
      }

      @include breakpoint(max, $mobile) {
        @include sizer(100%);
        grid-row: 1;
      }

      //   outline: 3px solid orange;
      //   outline-offset: 3px;

      .imgWrapper {
        @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        overflow: hidden;
        transform-origin: 0 100%;

        //transition: 0.8s all ease-in-out;

        // outline: 3px solid blue;
        // outline-offset: 3px;

        .img {
          @include sizer(100%);
          object-fit: cover;
          transform-origin: 50% 50%;

          display: block;

          //   outline: 3px solid red;
          //   outline-offset: -3px;
        }

        h1 {
          position: absolute;
          top: 2rem;
          left: 2rem;
          color: greenyellow;
          font-size: 5rem;
        }
      }
    }
  }

  .navMobile {
    background: #e8e7e780;
    grid-row: 2;

    display: flex;
    overflow: hidden;

    @include breakpoint(min, $mobile) {
      display: none;
    }

    .controls {
      border-right: 1px solid rgba(128, 128, 128, 0.2);
      padding: 0 0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .btn {
        background: none;
        border: none;
        cursor: pointer;
        padding: 1rem 1.5rem;
        transition: 0.4s all ease;

        &:nth-child(1) {
          .arrow {
            transform: rotate(180deg);
          }
        }

        &:nth-child(2) {
          border-top: 1px solid rgba(128, 128, 128, 0.2);
        }

        .arrow {
          @include sizer(1rem);
        }
      }
    }

    .navItems {
      @include sizer(100%);
      position: relative;

      .navItem {
        @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        padding: 1.5rem 1.25rem 1.5rem 2vw;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .itemIndex {
          color: #1a1818;
          font-family: "AuxMono";
          font-size: 2rem;
          letter-spacing: -0.1ch;
          line-height: 120%;
        }

        .text {
          color: #1a1818;
          font-family: "Helonik";
          font-size: 1.35rem;
          font-weight: 400;
          line-height: 120%;
          transition: 1s all ease-in-out;
        }
      }
    }
  }
}
