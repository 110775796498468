@import "gerillass";
@import "./_variables";

.horizontalImageText {
  background: #ffffff;
  padding: 10vh 5vw 10vh 5vw;
  position: relative;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(max, $tablet) {
    flex-direction: column-reverse;
    padding: 5vh 5vw;
  }

  @include breakpoint(max, $mobile) {
    flex-direction: column;
  }

  .mediaWrapper {
    @include sizer(35vw, 90vh);
    flex-shrink: 0;
    overflow: hidden;
    margin-left: 5vw;

    @include breakpoint(max, $tablet) {
      @include sizer(45vw, auto);
      margin-left: 0;
    }

    @include breakpoint(max, $mobile) {
      @include sizer(100%, auto);
      margin-bottom: 5vh;
    }

    &.horizontal {
      @include sizer(45vw, 55vh);
      margin-left: 2.5vw;

      @include breakpoint(max, $tablet) {
        @include sizer(100%, 50vw);
        margin-left: 0;
      }

      @include breakpoint(max, $mobile) {
        @include sizer(100%, auto);
      }
    }

    .videoWrapper {
      @include sizer(100%);
      overflow: hidden;
      position: relative;
      z-index: 100;

      .thumb {
        @include position(absolute, 0 0 0 0);
        background-image: url(../img/video-thumb.png);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        object-fit: cover;
        z-index: 20;

        &:hover {
          background-size: 110%;

          .play {
            opacity: 1;
          }
        }

        .play {
          @include position(absolute, 50% 0 0 50%);
          transform: translate(-50%, -50%);
          transition: 0.4s all ease;
          opacity: 0.5;
          cursor: pointer;

          @include breakpoint(max, $mobile) {
            @include sizer(20vw);
          }
        }
      }

      .video {
        @include sizer(100%);
        display: block;
        object-fit: cover;
        z-index: 10;
      }
    }

    .imgWrapper {
      position: relative;

      .img {
        @include sizer(100%, auto);
        display: block;
        object-fit: cover;
        object-position: 0 0;
      }
    }
  }

  .textWrapper {
    @include sizer(25vw, auto);
    color: #1a1818;
    font-family: Helonik;
    font-weight: 400;
    margin-bottom: 5vh;
    margin-right: 15vw;
    letter-spacing: 0.01px;
    text-align: left;

    &.horizontal {
      margin-right: 10vw;

      @include breakpoint(max, $tablet) {
        margin-right: 0;
      }
    }

    @include breakpoint(max, $tablet) {
      @include sizer(auto, auto);
      margin-bottom: 10vw;
      margin-left: 0;
      margin-right: 0;
    }

    @include breakpoint(max, $mobile) {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
    }

    .title {
      font-size: 2.25vw;
      line-height: 140%;
      margin-bottom: 7vh;

      @include breakpoint(max, $tablet) {
        font-size: 4.5vw;
        text-align: center;
        width: 100%;
      }

      @include breakpoint(max, $mobile) {
        font-size: 8vw;
        width: 100%;
      }
    }

    .text {
      font-size: 1.25rem;
      line-height: 180%;

      @include breakpoint(max, $tablet) {
        font-size: 2.25vw;
        text-align: center;
      }

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
        width: 100%;
      }
    }
  }
}
