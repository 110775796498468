@import "gerillass";
@import "./_variables";

.contact {
  background: #fff;
  position: relative;
  z-index: 100;

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7vh 8vw;

    @include breakpoint(max, $tablet) {
      flex-direction: column;
      padding: 7vh 5vw;
    }

    .text {
      color: #1a1818;
      font-family: Helonik;
      font-weight: 400;
      font-size: 2.5vw;
      line-height: 100%;

      @include breakpoint(max, $tablet) {
        font-size: 4.5vw;
        margin-bottom: 10vw;
        line-height: 140%;
      }

      @include breakpoint(max, $mobile) {
        font-size: 8vw;
        margin-bottom: 10vh;
      }
    }

    .formWrapper {
      width: 45vw;
    }
  }

  .col {
    color: #1a1818;
    font-family: AuxMono;
    margin-right: 5vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint(max, $tablet) {
      margin-right: 0;
      margin-left: 2vw;
      width: 15vw;
    }

    @include breakpoint(max, $mobile) {
      width: 100%;
      margin-bottom: 5vh;
      margin-right: 0;
    }

    .title {
      font-size: 1.25vw;
      letter-spacing: -0.2ch;
      margin-bottom: 1.5rem;
      opacity: 0.9;
      text-decoration: underline;

      @include breakpoint(max, $tablet) {
        font-size: 2vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
        margin-bottom: 0.5rem;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .link {
        color: #1a1818;
        font-size: 1.5vw;
        letter-spacing: -0.2ch;
        line-height: 160%;
        margin-bottom: 1rem;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
          white-space: inherit;
        }

        @include breakpoint(max, $tabletMini) {
          font-size: 10px;
        }

        @include breakpoint(max, $mobile) {
          font-size: 7vw;
          line-height: 200%;
        }

        &.address {
          width: 40vw;

          @include breakpoint(max, $tablet) {
            width: 50vw;
          }

          @include breakpoint(max, $mobile) {
            width: 100%;
          }
        }

        &.email,
        &.phone {
          font-size: 1vw;

          @include breakpoint(max, $tablet) {
            font-size: 1.5vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 4vw;
          }
        }
      }
    }
  }

  .middle {
    display: flex;
    padding: 7vh 8vw;
    position: relative;

    @include breakpoint(max, $tablet) {
      margin-top: 18vw;
      padding: 3vw 3vw 5vw 3vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 10vh 4vw 5vh 4vw;
      flex-direction: column;
    }

    &::before {
      content: "";
      @include position(absolute, 0 0 auto 50%);
      @include sizer(85%, 1px);
      background: #1a1818;
      transform: translateX(-50%);

      @include breakpoint(max, $tablet) {
        @include sizer(95%, 1px);
      }
    }

    .left {
      margin-right: 3vw;

      @include breakpoint(max, $tablet) {
        margin-right: 15vw;
      }

      @include breakpoint(max, $mobile) {
        margin-bottom: 10vh;
      }

      .addressWrapper {
        @include breakpoint(max, $tablet) {
          width: 35vw;
        }

        @include breakpoint(max, $mobile) {
          width: 70vw;
        }
      }
    }

    .right {
      display: flex;

      @include breakpoint(max, $tablet) {
        margin-left: auto;
      }

      @include breakpoint(max, $mobile) {
        margin-left: 0;
        margin-top: 5vh;
      }
    }

    .socialMobile {
      padding-left: 2vw;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include breakpoint(min, $mobile) {
        display: none;
      }
    }
  }

  .bottom {
    padding: 3vh 8vw;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      padding: 3vw 3vw;
    }

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
      padding: 3vh 4vw 2vh 4vw;
    }

    &::before {
      content: "";
      @include position(absolute, 0 0 auto 50%);
      @include sizer(85%, 1px);
      background: #1a1818;
      transform: translateX(-50%);

      @include breakpoint(max, $tablet) {
        @include sizer(95%, 1px);
      }
    }

    .copyright {
      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .cText {
        color: #1a1818;
        font-family: AuxMono;
        font-size: 14px;
        letter-spacing: -0.2ch;
        line-height: 100%;

        display: flex;
        align-items: center;

        @include breakpoint(max, $tablet) {
          font-size: 10px;
        }

        @include breakpoint(max, $mobile) {
          flex-direction: column;
          font-size: 12px;
          line-height: 180%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          width: 70%;

          @include breakpoint(max, $mobile) {
            font-size: 3vw;
            width: 100%;
          }

          .seperator {
            display: none;
          }

          .link {
            margin-top: 3vh;
          }
        }

        .seperator {
          padding: 0 0.5vw;
        }
      }
    }

    .privacy {
      margin-right: 3vw;

      @include breakpoint(max, $tablet) {
        margin-right: 0;
      }

      @include breakpoint(max, $mobile) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
      }

      .text {
        color: #1a1818;
        display: inline-block;
        font-family: AuxMono;
        font-size: 14px;
        letter-spacing: -0.2ch;
        line-height: 100%;
        margin-right: 1.5rem;

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          margin-right: 0;
          margin-bottom: 5vh;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 9vw;

      @include breakpoint(max, $mobile) {
        display: none;
      }

      .icon {
        padding-right: 3rem;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
