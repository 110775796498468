@import "gerillass";
@import "./_variables";

.preloader {
  @include position(fixed, 0 0 0 0);
  @include sizer(100%);
  background: #fff;
  z-index: 9999999999;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  @include breakpoint(max, $tablet) {
    align-items: center;
  }

  .logoWrapper {
    @include sizer(91vw, 35vh);
    overflow: hidden;
    position: relative;
    z-index: 50;

    @include breakpoint(max, $tablet) {
      @include sizer(91vw, 20vw);
    }

    .logo {
      @include position(absolute, auto 0 0 50%);
      transform: translateX(-50%);

      height: 90%;
      z-index: 10;
      line-height: 100%;
    }
  }

  .progress {
    @include position(absolute, 50% auto auto 50%);
    transform: translate(-50%, -50%);

    .indicator {
      color: #1a1818;
      font-family: "AuxMono";
      font-size: 4.5vw;
      font-weight: 400;
      letter-spacing: -0.2ch;
      line-height: 100%;

      @include breakpoint(max, $tablet) {
        font-size: 10vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 15vw;
      }

      .num {
        transform: translateX(-0.2ch);
      }
    }

    // .bar {
    //   @include sizer(39px);

    //   background: #1a1818;
    //   border-radius: 32px;
    //   position: relative;

    //   will-change: transform;

    //   .indicator {
    //     color: #1a1818;
    //     font-family: "AuxMono";
    //     font-size: 3.5vw;
    //     font-weight: 400;
    //     letter-spacing: -0.5vw;
    //     line-height: 120%;

    //     @include position(absolute, auto 0 40px auto);
    //   }
    // }
  }
}

.preloaderShort {
  @include position(fixed, 0 0 0 0);
  @include sizer(100%);
  background: #fff;
  z-index: 100000;
}
