@import "gerillass";
@import "./_variables.scss";

.btn {
  display: flex;
  align-items: center;

  &.sm {
    .iconWrapper {
      @include sizer(2.75vw);
    }

    .textWrapper {
      .text {
        font-size: 1.25vw;
      }
    }
  }

  .iconWrapper {
    @include sizer(3vw);
    background: transparent;
    border: 1px solid #1a1818;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $tablet) {
      @include sizer(2.25rem);
    }

    .icon {
      @include sizer(30%);
      position: absolute;
      z-index: 10000;
    }

    .icon2 {
      @include sizer(30%);
      position: absolute;
      z-index: 10;
    }

    .bg {
      background: #00509d;
      border-radius: 50%;
      height: 1px;
      position: absolute;
      transform-origin: 50% 50%;
      width: 1px;
    }
  }

  .textWrapper {
    margin-left: 1rem;

    @include breakpoint(max, $tablet) {
      margin-left: 0.5rem;
    }

    .text {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 1.5vw;
      font-weight: 300;
      line-height: 160%;

      @include breakpoint(max, $tablet) {
        font-size: 2.25vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
      }
    }
  }
}
