@import "gerillass";
@import "./variables";

.doubleTitle {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10vh 7vw;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  .title {
    font-family: Helonik;
    font-size: 3vw;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    width: 40vw;

    @include breakpoint(max, $tablet) {
      font-size: 3.5vw;
      width: 32vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 7.5vw;
      line-height: 160%;
      margin-bottom: 7.5vh;
      width: 100%;
    }
  }
}
