@import "gerillass";
@import "./variables";

.techDetails {
  padding: 15vh 7vw;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 10vh 5vw;
  }

  .detail {
    align-items: flex-start;
    display: flex;
    margin-bottom: 20vh;

    @include breakpoint(max, $tablet) {
      align-items: center;
      margin-bottom: 15vh;
    }

    @include breakpoint(max, $mobile) {
      align-items: center;
      flex-direction: column-reverse;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @include breakpoint(max, $mobile) {
        align-items: center;
        flex-direction: column-reverse;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .imgWrapper {
      @include sizer(775px, 516px);
      flex-shrink: 0;
      margin-top: 12.5vh;
      overflow: hidden;

      @include breakpoint(max, $tablet) {
        @include sizer(40vw, 20vh);
        margin-top: 0;
      }

      @include breakpoint(max, $mobile) {
        @include sizer(100vw, 40vh);
        margin: 5vh 0;
      }

      .img {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .text {
      .title {
        color: #0466c8;
        font-family: "Helonik";
        font-size: 1.8vw;
        font-weight: 400;
        line-height: 140%;
        margin-left: 8vw;
        margin-bottom: 10vh;
        position: relative;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
          margin-bottom: 3vh;
          margin-left: 4vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 9vw;
          margin-bottom: 5vh;
          margin-left: 0;
          text-align: center;
        }

        br {
          @include breakpoint(max, $tablet) {
            display: none;
          }
        }

        span {
          font-family: "AuxMono";
          letter-spacing: -0.2ch;
          transform: translateX(-0.2ch);
        }

        .index {
          @include position(absolute, -30% auto auto -25%);
          color: #0466c8;
          font-family: "AuxMono";
          font-size: 5vw;
          font-weight: 300;
          letter-spacing: -0.2ch;
          line-height: 100%;
          text-align: left;
          transform: translateX(-0.2ch);

          @include breakpoint(max, $tablet) {
            @include position(absolute, -150% auto auto 0);
            font-size: 4vw;
          }

          @include breakpoint(max, $mobile) {
            @include position(absolute, -40% auto auto 50%);
            transform: translateX(-50%);
            font-size: 10vw;
            text-align: center;
          }
        }
      }

      .text {
        color: #333333;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        line-height: 170%;
        margin-bottom: 3vh;
        margin-left: 8vw;
        margin-right: 5vw;
        text-align: left;
        width: 26vw;

        @include breakpoint(max, $tablet) {
          font-size: 1.8vw;
          margin-bottom: 1vh;
          margin-left: 4vw;
          width: 45vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          margin-bottom: 5vh;
          margin-left: 0;
          text-align: center;
          width: 100%;
        }

        strong {
          font-weight: 700;
        }
      }
    }
  }
}
