@import "gerillass";
@import "../scss/variables";

.referencesMobile {
  margin: 10vh 0;

  @include breakpoint(max, $mobile) {
    margin: 0 0 10vh 0;
  }

  .row {
    @include sizer(100%, 65vh);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logoWrapper {
      @include sizer(60vw, 13vh);
      display: block;
      margin-bottom: 3vh;
      overflow: hidden;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      flex-shrink: 0;

      &::after {
        content: "";
        @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        border: 1px solid #cae9ff;
        border-radius: 80px;
        transition: 0.2s all ease;
        pointer-events: none;
      }

      .companyLogo {
        max-height: 50%;
        max-width: 75;
      }
    }
  }
}
