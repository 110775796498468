@import "gerillass";
@import "./variables";

.heroServices {
  background: #ffffff;
  position: relative;
  z-index: 110;

  .top {
    position: relative;
    padding: 15vh 7vw 10vh 7vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      padding: 7vw 5vw 5vh 5vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 12vw;
      padding: 10vh 5vw 4vh 5vw;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @include breakpoint(max, $tablet) {
        flex-direction: column;
      }

      .bcWrapper {
        margin-bottom: 3vh;

        @include breakpoint(max, $tablet) {
          margin-bottom: 0;
        }
      }

      .title {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 5vw;
        font-weight: 700;
        letter-spacing: 0.08vw;
        line-height: 110%;
        margin-bottom: 8vh;
        text-align: left;
        width: 50vw;

        @include breakpoint(max, $tablet) {
          font-size: 7vw;
          margin-bottom: 5vh;
          width: 60vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 10vw;
          margin-bottom: 5vh;
          width: 100%;
        }
      }

      .desc {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        font-style: italic;
        line-height: 180%;
        text-align: left;
        width: 35vw;

        @include breakpoint(max, $tablet) {
          font-size: 2.25vw;
          width: 50vw;
          margin-top: 0;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          width: 100%;
        }
      }
    }

    .videoWrapper {
      @include sizer(30vw);
      margin-right: 5vw;

      @include breakpoint(max, $tablet) {
        @include sizer(50vw);
        margin-right: 0;
      }

      @include breakpoint(max, $mobile) {
        display: none;
      }

      .video {
        @include sizer(100%);
        object-fit: contain;
      }
    }
  }

  .imgWrapper {
    @include sizer(95vw, 90vh);
    margin: 0 auto;
    overflow: hidden;

    @include breakpoint(max, $tablet) {
      @include sizer(100vw, 45vh);
    }

    @include breakpoint(max, $mobile) {
      @include sizer(100vw, 60vh);
    }

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
      transform-origin: 50% 50%;
      will-change: transform;
    }
  }

  .bottom {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 12vh 12vw 5vh 12vw;

    @include breakpoint(max, $tablet) {
      flex-direction: column;
      padding: 10vh 7vw 5vh 7vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 7vh 7vw 5vh 7vw;
      display: flex;
      align-items: center;
    }

    .title {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 2.7vw;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 9vh;
      text-align: left;
      width: 37vw;

      @include breakpoint(max, $tablet) {
        font-size: 3.5vw;
        width: 80vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
        line-height: 160%;
        margin-bottom: 5vh;
        text-align: center;
        width: 100%;
      }

      span {
        color: #0466c8;
        font-family: "AuxMono";
        letter-spacing: -0.2ch;
        // text-decoration: underline;
      }
    }

    .texts {
      display: flex;
      flex-direction: column;
      margin-top: 25vh;
      width: 25vw;

      @include breakpoint(max, $tablet) {
        flex-direction: row;
        margin-top: 0;
        width: 80vw;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .par {
        color: #333333;
        font-family: "Helonik";
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 5vh;
        text-align: left;
        flex: 1;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          line-height: 180%;
          width: 35vw;
          margin-right: 5vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4.5vw;
          line-height: 160%;
          margin-right: 0;
          text-align: center;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }

  .bgWrapper {
    @include position(absolute, auto auto -17% -20%);
    @include sizer(80vw, auto);

    .bg {
      @include sizer(100%);
      display: block;
      object-fit: contain;
    }
  }

  .floatingImgWrapper {
    @include position(absolute, auto auto -10% 0);
    @include sizer(35vw, 45vh);
    overflow: hidden;

    @include breakpoint(max, $tablet) {
      @include position(absolute, auto auto -3% 0);
      @include sizer(37vw, 20vh);
    }

    @include breakpoint(max, $mobile) {
      @include position(relative, auto auto -3% 0);
      @include sizer(100vw, 40vh);
      margin-bottom: 5vh;
    }

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
    }
  }
}
