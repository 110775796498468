@import "gerillass";
@import "./variables";

.textWithIcon {
  background: #cae9ff;
  padding: 15vh 10vw;
  position: relative;
  z-index: 110;

  display: flex;
  justify-content: flex-end;

  @include breakpoint(max, $tablet) {
    padding: 10vh 7vw 15vh 7vw;
    justify-content: flex-start;
  }

  .imgWrapper {
    @include position(absolute, auto auto -10% -10%);
    @include sizer(50%, auto);
    transform: rotateY(180deg);

    @include breakpoint(max, $tablet) {
      @include position(absolute, auto 0 -10% auto);
      @include sizer(32%, auto);
      transform: rotateY(0deg);
    }

    @include breakpoint(max, $mobile) {
      @include position(absolute, auto 0 0 -50%);
      @include sizer(150%, auto);
      opacity: 0.08;
      transform: rotateY(180deg);
    }

    .img {
      @include sizer(100%);
      display: block;
      object-fit: cover;
    }
  }

  .texts {
    width: 60%;

    @include breakpoint(max, $tablet) {
      width: 95%;
    }

    @include breakpoint(max, $mobile) {
      width: 100%;
    }

    .title {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 2.1vw;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 10vh;
      text-align: left;
      width: 40vw;

      @include breakpoint(max, $tablet) {
        font-size: 4vw;
        width: 100%;
      }

      @include breakpoint(min, $mobile) {
        span {
          color: #0466c8;
          font-family: "AuxMono";
          letter-spacing: -0.2ch;
        }
      }

      @include breakpoint(max, $mobile) {
        font-size: 6.5vw;
        text-align: center;
        width: 100%;
      }
    }

    .desc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include breakpoint(max, $tablet) {
        justify-content: flex-start;
      }

      @include breakpoint(max, $mobile) {
        align-items: center;
        flex-direction: column;
      }

      .par {
        color: #1a1818;
        display: block;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        line-height: 170%;
        margin-right: 2vw;
        text-align: left;
        width: 50%;

        &:last-child {
          margin-right: 0;
        }

        @include breakpoint(max, $tablet) {
          font-size: 2.25vw;
          margin-right: 10vw;
          width: 40%;
        }

        @include breakpoint(max, $mobile) {
          font-size: 5vw;
          margin-bottom: 5vh;
          margin-right: 0;
          text-align: center;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
