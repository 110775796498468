@import "gerillass";
@import "./variables";

.hero {
  position: relative;
  z-index: 100;

  &.whiteBg {
    .bottom {
      background: #ffffff;
    }
  }

  .top {
    position: relative;
    padding: 12vh 7vw 35vh 7vw;

    @include breakpoint(max, $tablet) {
      padding: 7vw 5vw 16vw 5vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 12vw;
      padding: 10vh 5vw 4vh 5vw;
    }

    .bcWrapper {
      margin-bottom: 3vh;

      @include breakpoint(max, $tablet) {
        margin-bottom: 0;
      }
    }

    .text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include breakpoint(max, $tablet) {
        flex-direction: column;
      }

      .title {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 5vw;
        font-weight: 700;
        letter-spacing: 0.08vw;
        line-height: 110%;
        margin-bottom: 0vw;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 8vw;
          margin-bottom: 5vh;
          width: 70vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 10vw;
          margin-bottom: 5vh;
          width: 100%;
        }
      }

      .desc {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        font-style: italic;
        line-height: 170%;
        margin-top: 0vh;
        text-align: left;
        width: 25vw;

        @include breakpoint(max, $tablet) {
          font-size: 2.25vw;
          width: 50vw;
          margin-top: 0;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          width: 100%;
        }
      }
    }

    .imgWrapper {
      @include position(absolute, auto 0 -45% 0);
      @include sizer(85vw, 55vh);
      margin: 0 auto;
      overflow: hidden;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);

      @include breakpoint(max, $tablet) {
        @include position(absolute, 100% 0 auto 0);
        @include sizer(100vw, 32vh);
      }

      @include breakpoint(max, $mobile) {
        @include position(absolute, 100% 0 auto 0);
        @include sizer(100vw, 60vh);
      }

      .img {
        @include sizer(100%, auto);
        display: block;
        transform-origin: 50% 50%;
        will-change: transform;

        @include breakpoint(max, $tablet) {
          @include sizer(100%);
          object-fit: cover;
        }

        @include breakpoint(max, $tablet) {
          @include sizer(100%);
          object-fit: cover;
        }
      }
    }

    .imgWrapper2 {
      @include position(absolute, 120% 12% auto auto);
      @include sizer(22vw, 70vh);
      overflow: hidden;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);

      @include breakpoint(max, $tablet) {
        @include position(absolute, 140% 5% auto auto);
        @include sizer(30vw, 37vh);
      }

      @include breakpoint(max, $mobile) {
        display: none;
      }

      &.circle {
        @include sizer(27vw);
        border-radius: 50%;

        @include breakpoint(max, $tablet) {
          @include sizer(35vw);
        }
      }

      .img {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }
  }

  .bottom {
    background: #cae9ff;
    padding: 60vh 12vw 25vh 12vw;
    position: relative;
    z-index: -1;

    @include breakpoint(max, $tablet) {
      padding: 40vh 7vw 10vh 7vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 70vh 7vw 10vh 7vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .desc {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 2.1vw;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 9vh;
      text-align: left;
      width: 37vw;
      font-smooth: antialias;

      @include breakpoint(max, $tablet) {
        font-size: 3.5vw;
        width: 55vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
        line-height: 160%;
        margin-bottom: 5vh;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
      }

      span {
        color: #0466c8;
        font-family: "AuxMono";
        letter-spacing: -0.2ch;
      }
    }

    .texts {
      display: flex;
      width: 70%;

      @include breakpoint(max, $tablet) {
        width: 100%;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column;
        align-items: center;
      }

      .text {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 160%;
        margin-right: 7vw;
        text-align: left;
        flex: 1;

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
          line-height: 160%;
          margin-right: 2vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4.5vw;
          line-height: 160%;
          margin-right: 0;
          margin-top: 5vh;
          text-align: center;
          width: 100%;
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }
}
