@import "gerillass";
@import "./variables";

.imageWithText {
  background: #ffffff;
  position: relative;
  z-index: 10000;

  .mediaWrapper {
    display: inline-block;

    .imgWrapper {
      @include sizer(100%);
      z-index: 100;
      overflow: hidden;

      .img {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .videoWrapper {
      overflow: hidden;
      position: relative;
      z-index: 100;

      .thumb {
        @include position(absolute, 0 0 0 0);
        background-image: url(../img/video-thumb.png);
        background-size: 100%;
        background-position: center;
        cursor: pointer;
        object-fit: cover;
        z-index: 20;

        &:hover {
          background-size: 110%;

          .play {
            opacity: 1;
          }
        }

        .play {
          @include position(absolute, 50% 0 0 50%);
          transform: translate(-50%, -50%);
          transition: 0.4s all ease;
          opacity: 0.5;
          cursor: pointer;
          z-index: 30;

          @include breakpoint(max, $mobile) {
            @include sizer(30vw);
          }
        }
      }

      .video {
        @include sizer(100%);
        background: #002855;
        display: block;
        object-fit: cover;
        z-index: -1;
      }
    }

    .iconWrapper {
      @include sizer(20vw);
      z-index: 200;

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: contain;
      }
    }
  }

  .singleText {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 170%;
    margin-left: auto;
    margin-right: 5vw;
    text-align: left;
    width: 30vw;

    @include breakpoint(max, $tablet) {
      font-size: 2.5vw;
      margin-right: 0;
      width: 60vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 4.5vw;
      width: 100%;
    }

    strong {
      font-weight: 500;
    }
  }

  .textWithTitle {
    margin-right: auto;

    .title {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 2.7vw;
      font-weight: 400;
      letter-spacing: 0.01px;
      line-height: 140%;
      margin-bottom: 6vw;
      text-align: left;
      width: 40vw;

      @include breakpoint(max, $tablet) {
        font-size: 4.5vw;
        margin-bottom: 7vh;
        width: 70vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
        margin-bottom: 5vh;
        text-align: center;
        width: 100%;
      }
    }

    .text {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 1.2vw;
      font-weight: 400;
      line-height: 180%;
      text-align: left;
      width: 30vw;

      @include breakpoint(max, $tablet) {
        font-size: 2.5vw;
        width: 60vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
        text-align: center;
        width: 100%;
      }
    }
  }

  &.left {
    padding: 30vh 5vw 10vh 5vw;

    @include breakpoint(max, $tablet) {
      padding: 55vh 5vw 0vh 5vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 55vh 5vw 25vh 5vw;
    }

    .mediaWrapper {
      @include position(absolute, -10vh auto auto 7vw);
      @include sizer(48vw, 60vh);

      @include breakpoint(max, $tablet) {
        @include position(absolute, 0 auto auto 0);
        @include sizer(100vw, 45vh);
      }

      .iconWrapper {
        @include position(absolute, 100% auto auto auto);
        @include sizer(20vw);

        @include breakpoint(max, $tablet) {
          @include position(absolute, auto auto auto -15%);
          @include sizer(45vw);
        }

        @include breakpoint(max, $mobile) {
          opacity: 0.4;
          @include position(absolute, 200% auto auto 5vw);
          @include sizer(60vw);
        }
      }
    }

    .textWithTitle {
      display: none;
    }
  }

  &.center {
    padding: 30vh 5vw 30vh 5vh;

    @include breakpoint(max, $tablet) {
      padding: 30vh 5vw 10vh 5vh;
    }

    @include breakpoint(max, $mobile) {
      padding: 30vh 5vw 30vh 5vh;
    }

    .mediaWrapper {
      @include position(absolute, -10vh auto auto 50%);
      @include sizer(60vw, 70vh);
      transform: translateX(-50%);

      @include breakpoint(max, $tablet) {
        @include position(absolute, 0 auto auto 50%);
        @include sizer(100vw, 45vh);
      }

      .iconWrapper {
        @include position(absolute, auto auto -50% -20%);

        @include breakpoint(max, $tablet) {
          @include position(absolute, auto auto -50% 0);
          @include sizer(35vw);
        }

        @include breakpoint(max, $mobile) {
          @include position(absolute, auto auto -60% 5%);
          @include sizer(50vw);
        }
      }
    }

    .singleText {
      display: none;
    }

    .textWithTitle {
      display: none;
    }
  }

  &.right {
    padding: 15vh 12vw 20vh 12vw;

    @include breakpoint(max, $tablet) {
      padding: 32vh 7vw 7vh 7vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 45vh 7vw 5vh 7vw;
    }

    .mediaWrapper {
      .imgWrapper {
        @include position(absolute, auto 0 5vw auto);
        @include sizer(40vw, 55vh);

        @include breakpoint(max, $tablet) {
          @include position(absolute, -7% 0 auto auto);
          @include sizer(60vw, 30vh);
        }

        @include breakpoint(max, $mobile) {
          @include position(absolute, 0 0 auto auto);
          @include sizer(100vw, 40vh);
        }
      }

      .iconWrapper {
        display: none;
      }
    }

    .singleText {
      display: none;
    }
  }
}
