@import "gerillass";
@import "./variables";

.solutionsList {
  // margin-top: 10vh;
  background: #ffffff;
  padding-top: 10vh;
  position: relative;
  z-index: 5000;

  // box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
  //   rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
  //   rgba(0, 0, 0, 0.09) 0px 32px 16px;

  @include breakpoint(max, $mobile) {
    padding-bottom: 5vh;
    padding-top: 5vh;
  }

  &.blueBg {
    background: #cae9ff;
  }

  .pre {
    display: flex;
    margin-bottom: 10vh;
    margin-left: auto;
    margin-right: 8vw;
    width: max-content;

    @include breakpoint(max, $tablet) {
      margin-bottom: 7vh;
      margin-right: 5vw;
    }

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
      margin-bottom: 0;
      padding: 0 5vw;
      width: 100%;
    }

    &.leftAligned {
      margin-right: auto;
      margin-left: 8vw;
      flex-direction: row-reverse;

      @include breakpoint(max, $tablet) {
        margin-left: 5vw;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
        margin-left: 0;
      }

      .arrow {
        transform: rotate(0deg);

        @include breakpoint(max, $mobile) {
          margin-left: 0;
          margin-left: auto;
          transform: rotate(90deg);
        }
      }
    }

    .arrow {
      @include sizer(5vw);
      margin-top: auto;
      margin-right: 2rem;
      transform: rotate(90deg);

      @include breakpoint(max, $tablet) {
        @include sizer(7vw);
      }

      @include breakpoint(max, $mobile) {
        @include sizer(20vw);
        margin-left: auto;
        margin-right: 0;
        margin-top: 3vh;
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .text {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 2.1vw;
      font-weight: 300;
      line-height: 140%;
      text-align: left;
      width: 32vw;

      @include breakpoint(max, $tablet) {
        font-size: 3vw;
        width: 50vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6.5vw;
        text-align: right;
        width: 100%;
      }

      span {
        color: #0466c8;
        font-family: "AuxMono";
        letter-spacing: -0.2ch;
      }
    }
  }

  .listItem {
    display: flex;
    padding: 5vh 5vw 10vh 5vw;
    position: relative;

    @include breakpoint(max, $tablet) {
      padding: 2vh 5vw 5vh 5vw;
    }

    @include breakpoint(max, $mobile) {
      align-items: flex-start;
      flex-direction: column;
      padding: 8vh 5vw;
    }

    &::after {
      content: "";
      @include position(absolute, auto 0 0 0);
      @include sizer(90%, 1px);
      margin: 0 auto;
      background: #1a1818;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    .indexWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .itemIndex {
        color: #0466c8;
        font-family: "AuxMono";
        font-size: 9vw;
        font-weight: 400;
        letter-spacing: -0.2ch;
        line-height: 100%;
        text-align: center;
        transform: translateX(-0.2ch);

        @include breakpoint(max, $tablet) {
          font-size: 10vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 9vw;
        }
      }
    }

    .title {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 140%;
      margin-left: auto;
      margin-right: 5vw;
      text-align: left;
      width: 15vw;

      @include breakpoint(max, $tablet) {
        font-size: 2vw;
        width: 20vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 8vw;
        line-height: 120%;
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 3vh;
        width: 80%;
      }
    }

    .desc {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 1rem;
      font-weight: 400;
      line-height: 200%;
      text-align: left;
      width: 40vw;

      @include breakpoint(max, $tablet) {
        font-size: 1.5vw;
        width: 50vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 4vw;
        line-height: 180%;
        width: 100%;
      }
    }
  }
}
