@import "gerillass";
@import "./variables";

.productInfo {
  background: #ffffff;
  overflow: hidden;
  padding: 12vh 10vw 12vh 12vw;
  position: relative;
  z-index: 5000;
  transform: translateZ(100);

  @include breakpoint(max, $tablet) {
    padding: 7vh 6vw;
  }

  .title {
    color: #0466c8;
    font-family: "Helonik";
    font-size: 5.4vw;
    font-weight: 900;
    line-height: 100%;
    margin-right: auto;
    margin-bottom: 8vh;
    text-align: left;
    width: 53vw;

    @include breakpoint(max, $tablet) {
      font-size: 8vw;
      margin-right: 0;
      width: 100%;
    }

    @include breakpoint(max, $mobile) {
      font-size: 10vw;
      line-height: 120%;
      margin-bottom: 5vh;
      text-align: center;
    }
  }

  .dualText {
    .iconWrapper {
      @include position(absolute, 15vw 5vw auto auto);
      @include sizer(25vw);
      margin-top: auto;

      @include breakpoint(max, $tablet) {
        @include position(absolute, 35vw 0 auto auto);
        @include sizer(30vw);
      }

      @include breakpoint(max, $mobile) {
        @include position(relative, auto auto auto auto);
        @include sizer(70vw, auto);
        margin: 0 auto 5vh auto;
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: contain;
      }
    }

    .descWrapper {
      margin-bottom: 7vh;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint(max, $mobile) {
        flex-direction: column;
        align-items: center;
      }

      .desc {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 2.5vw;
        font-weight: 400;
        line-height: 140%;
        margin-right: 5vw;
        text-align: left;
        width: 42vw;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
          line-height: 160%;
          width: 60vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 6vw;
          margin-right: 0;
          text-align: center;
          width: 100%;
        }
      }
    }

    .texts {
      display: flex;

      @include breakpoint(max, $mobile) {
        flex-direction: column;
      }

      .text {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 5vh;
        margin-right: 10vw;
        text-align: left;
        width: 25vw;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          width: 35vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          margin-bottom: 5vh;
          text-align: center;
          width: 100%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .dualDesc {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include breakpoint(max, $mobile) {
      flex-direction: column;
    }

    .iconWrapper {
      @include position(absolute, 5vw 0 auto auto);
      @include sizer(20vw);
      margin-left: auto;
      margin-bottom: 5vw;
      transform: translateY(-50%);

      @include breakpoint(max, $tablet) {
        @include sizer(32vw);
        transform: translateY(-10%);
      }

      @include breakpoint(max, $mobile) {
        @include position(relative, auto auto auto auto);
        @include sizer(70vw);
        margin: 0 auto 5vh auto;
        transform: translateY(0);
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: contain;
      }
    }

    .descWrapper {
      display: flex;
      flex-direction: column;

      @include breakpoint(max, $mobile) {
        align-items: center;
      }

      &.right {
        margin-top: 15vw;

        @include breakpoint(max, $tablet) {
          margin-top: 35vw;
        }
      }

      .desc {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 2.7vw;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 5vw;
        text-align: left;
        width: 38vw;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
          font-weight: 500;
          width: 45vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 6vw;
          text-align: center;
          width: 100%;
        }
      }

      .text {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.2vw;
        font-weight: 400;
        line-height: 180%;
        text-align: left;
        width: 24vw;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          width: 40vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}
