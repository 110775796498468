@import "gerillass";
@import "./variables";

.pinnedMobile {
  background: #e2f2ff;
  margin: 5vh 0;
  padding: 2vw 0 5vw 0;
  display: none;

  @include breakpoint(max, $tablet) {
    display: block;
  }

  @include breakpoint(max, $mobile) {
    margin: 0;
    padding: 5vh 0 10vh 0;
  }

  .pinnedItem {
    $width: 90vw;
    @include sizer($width, 50vh);
    border-radius: 8px;
    border: 1px solid #000000;
    margin: calc((100vw - $width) / 2);
    padding: 5vh 5vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      @include sizer(90vw, 50vh);
      padding: 2vh 2vw;
    }

    @include breakpoint(max, $mobile) {
      @include sizer(90vw, 80vh);
      margin: 5vw;
    }

    .title {
      background: #ffffff;
      color: #333333;
      display: inline-block;
      border-radius: 64px;
      font-family: Helonik;
      font-size: 3.5vw;
      margin-bottom: 4vh;
      padding: 1.5rem 2.5rem;
      white-space: nowrap;
      width: max-content;

      @include breakpoint(max, $mobile) {
        font-size: 4.5vw;
        margin-bottom: 4vh;
        padding: 1rem 2rem;
        white-space: nowrap;
      }
    }

    .text {
      color: #333333;
      font-family: Helonik;
      font-size: 3vw;
      line-height: 160%;
      text-align: center;
      width: 70vw;
      @include antialias;

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
        line-height: 140%;
      }

      span {
        font-family: AuxMono;
        text-decoration: underline;
        letter-spacing: -0.2ch;
      }
    }

    .btnWrapper {
      @include breakpoint(max, $mobile) {
        padding-left: 2.5vw;
      }
    }

    .videoWrapper {
      @include sizer(30vw);
      z-index: 1000;

      @include breakpoint(max, $mobile) {
        @include sizer(60vw);
      }

      .video {
        @include sizer(100%);

        @include breakpoint(max, $mobile) {
          @include sizer(60vw);
        }
      }
    }
  }

  // .dots {
  //   @include position(absolute, auto auto -3% 50%);
  //   transform: translateX(-50%);
  //   display: flex;

  //   .dot {
  //     @include sizer(1rem);
  //     border: 1px solid #000000;
  //     border-radius: 50%;
  //     margin-right: 0.25rem;
  //     transform: scale(0.8);
  //     transition: 0.8s all ease;
  //     transition-delay: 0.4s;

  //     &:last-child {
  //       margin-right: 0;
  //     }

  //     &.active {
  //       background: #cae9ff;
  //       border: 1px solid #cae9ff;
  //       transform: scale(1.2);
  //     }
  //   }
  // }
}
