@import "gerillass";
@import "./_variables";

.pinnedInfo {
  padding: 5vh 5vw 7.5vw 5vw;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(max, $tablet) {
    padding: 5vh 3vw;
  }

  @include breakpoint(max, $mobile) {
    flex-direction: column;
    padding: 5vh 5vw 10vh 5vw;
  }

  .nav {
    @include breakpoint(max, $mobile) {
      margin-bottom: 10vw;
      width: 100%;
    }

    .navItems {
      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .navItem {
        border-bottom: 1px solid #000000;
        cursor: pointer;
        margin-bottom: 1vh;
        opacity: 0.2;
        padding: 0.75rem 0;
        transition: 0.4s all ease;
        width: 25vw;

        display: flex;
        align-items: center;
        justify-content: space-between;

        * {
          cursor: pointer;
        }

        @include breakpoint(min, $tabletPlusOne) {
          &:hover {
            opacity: 1;

            .itemText {
              transform: translateX(1rem);

              @include breakpoint(max, $tablet) {
                transform: translateX(0);
              }
            }
          }
        }

        @include breakpoint(max, $tablet) {
          flex: 1;
          padding: 0.25rem 0;
          width: 32vw;
        }

        @include breakpoint(max, $mobile) {
          margin: 0 auto 5vw auto;
          padding: 0.25rem 0;
          width: 100%;
        }

        &.active {
          opacity: 1;

          .itemText {
            transform: translateX(1rem);

            @include breakpoint(max, $tablet) {
              transform: translateX(0.5rem);
            }
          }

          .arrow {
            transform: rotate(0);
          }
        }

        .itemText {
          color: #1a1818;
          font-family: "Helonik";
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 140%;
          text-align: left;
          transition: 0.4s all ease;

          @include breakpoint(max, $tablet) {
            font-size: 1rem;
          }

          @include breakpoint(max, $mobile) {
            @include sizer(100%, auto);
            font-size: 1.25rem;
          }
        }

        .arrow {
          @include sizer(1rem);
          transform: rotate(45deg);
          transition: 0.4s all ease;
        }
      }
    }
  }

  .details {
    // @include sizer(50vw, 60vh);
    position: relative;
    display: grid;
    margin-left: 10vw;
    transition: 1s height ease-in-out;

    @include breakpoint(max, $mobile) {
      margin-left: 0;
      width: 100%;
    }

    .detail {
      // @include position(absolute, 0 0 0 0);
      // @include sizer(100%);
      opacity: 0;
      padding-left: 2vw;
      pointer-events: none;
      transition: 0.6s all ease;
      transition-delay: 0.2s;

      grid-column: 1;
      grid-row: 1;

      @include breakpoint(max, $tablet) {
        padding-left: 4vw;
      }

      @include breakpoint(max, $mobile) {
        padding-left: 0;
        width: 100%;
      }

      &.active {
        opacity: 1;
        pointer-events: visible;
      }

      .heightWrapper {
        position: relative;
      }

      .detailText {
        color: #1a1818;
        font-family: Helonik;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 4vh;
        text-align: left;
        width: 80%;

        @include breakpoint(max, $tablet) {
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 160%;
          width: 100%;
        }

        @include breakpoint(max, $mobile) {
          width: 100%;
        }
      }

      .detailList {
        .detailItem {
          margin-bottom: 2vw;
          display: flex;
          align-items: center;

          @include breakpoint(max, $mobile) {
            margin-bottom: 7vw;
          }

          .arrow {
            @include sizer(6px, auto);
            flex-shrink: 0;
            margin-right: 1rem;
          }

          .detailItemText {
            color: #1a1818;
            font-family: "Helonik";
            font-size: 1rem;
            font-weight: 400;
            line-height: 180%;
            text-align: left;
            width: 80%;

            @include breakpoint(max, $tablet) {
              font-size: 1rem;
              font-weight: 300;
              line-height: 160%;
            }

            @include breakpoint(max, $mobile) {
              font-size: 16px;
              width: 100%;
            }
          }
        }
      }

      .btnWrapper {
        cursor: pointer;
        margin-top: 5vh;
      }
    }
  }
}

.videoWrapper {
  .video {
    @include sizer(auto, 80vh);

    @include breakpoint(max, $tablet) {
      @include sizer(90vw, auto);
    }
  }
}
