@import "gerillass";
@import "./_variables";

.notFound {
  @include sizer(100vw, 70vh);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .text {
    font-family: AuxMono;
    font-size: 7vw;
    font-weight: 400;
    letter-spacing: -0.15ch;
    margin-bottom: 5vw;

    @include breakpoint(max, $tablet) {
      font-size: 8vw;
      margin-bottom: 7.5vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 9vw;
      margin-bottom: 10vw;
    }
  }

  .link {
    font-family: Helonik;
    font-size: 2vw;
    font-weight: 400;
    text-decoration: underline;

    @include breakpoint(max, $tablet) {
      font-size: 3.5vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 5vw;
    }
  }
}
