@import "gerillass";
@import "../scss/variables.scss";

.cursorWrapper {
  @include position(fixed, auto auto 0 0);
  @include sizer(100vw, 100vh);
  background: transparent;
  pointer-events: none;

  z-index: 99999;

  .magnetCursor {
    @include position(absolute, 0 auto auto 0);
    border-radius: 50%;
    z-index: 99999999999999;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $tablet) {
      display: none;
    }

    .cursorDefault {
      @include position(absolute, 0 0 0 0);
      @include sizer(1rem);
      background: #00509d;
      border-radius: 50%;
      transform-origin: center center;
      opacity: 0;
      transition: 0.2s all ease;
      will-change: transform opacity;

      &.visible {
        opacity: 1;
      }

      &.circled {
        background: transparent;
        border: 1px solid #00509d;
        display: block;
        transform: scale(3);
      }

      &.scaled {
        transform: scale(1.5);
      }

      &.hidden {
        transform: scale(3);
        opacity: 0;
      }
    }

    .cursorCircle {
      @include position(absolute, 0 0 auto auto);
      transform-origin: center center;
      opacity: 0;
    }
  }
}
