@import "gerillass";
@import "./_variables";

.homeWrapper {
  position: relative;
  z-index: 100;

  .digital {
    padding: 25vh 10vw 15vh 10vw;
    position: relative;
    z-index: 700;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $tablet) {
      padding: 20vh 10vw 10vh 10vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 20vw 2.5vw;
    }

    &::after {
      content: "";
      background: #1d4268;
      @include position(absolute, auto 0 0 50%);
      transform: translateX(-50%);
      @include sizer(95%, 1px);
    }

    .small {
      color: #1a1818;
      display: block;
      font-family: "Helonik";
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 5.5vw;
      text-align: center;

      @include breakpoint(max, $tablet) {
        font-size: 2.5vw;
        margin-bottom: 10vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
        margin-bottom: 10vw;
      }
    }

    .titleWrapper {
      width: 70vw;
      margin-bottom: 10vh;
      text-align: center;

      @include breakpoint(max, $tablet) {
        margin-bottom: 5vh;
      }

      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .textWithImage {
        position: relative;

        .title {
          font-size: 5vw;
          font-family: "AuxMono";
          font-weight: 400;
          color: #0466c8;
          letter-spacing: -0.2ch;
          line-height: 110%;
          display: inline-block;
          position: relative;
          white-space: pre;

          @include breakpoint(max, $tablet) {
            font-size: 7vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 10vw;
            margin-top: 25vh;
            line-height: 150%;
          }

          &.left {
            margin-right: 15vw;

            @include breakpoint(max, $tablet) {
              margin-right: 14vw;
            }

            @include breakpoint(max, $mobile) {
              margin-right: 2.5vw;
            }
          }
        }

        .ellipsis {
          @include position(absolute, -75% 0 0 36%);
          width: 20vw;

          @include breakpoint(max, $tablet) {
            @include position(absolute, -35% 0 0 36.5%);
          }

          @include breakpoint(max, $mobile) {
            @include position(absolute, -5% 0 0 10%);
            width: 80vw;
          }
        }
      }

      .titleHelonik {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 4.5vw;
        font-weight: 400;
        line-height: 120%;

        @include breakpoint(max, $tablet) {
          font-size: 5vw;
          line-height: 130%;
        }

        @include breakpoint(max, $mobile) {
          font-size: 8vw;
        }
      }
    }

    .btnWrapper {
      @include breakpoint(max, $tablet) {
        margin-top: 5vh;
      }

      @include breakpoint(max, $mobile) {
        margin-left: 0;
        margin-top: 10vh;
      }
    }
  }

  .services {
    padding: 5vh 10vw;

    display: flex;
    justify-content: space-between;

    @include breakpoint(max, $mobile) {
      padding: 14vw 5vw 0 5vw;
      flex-direction: column-reverse;
    }

    .left {
      @include breakpoint(max, $mobile) {
        margin-top: 15vh;
      }

      .title {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 6vw;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 3vw;

        @include breakpoint(max, $tablet) {
          font-size: 5vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 14vw;
        }
      }

      .aSquareWrapper {
        @include breakpoint(max, $mobile) {
          width: 20vw;
        }
      }
    }

    .right {
      padding-left: 10vw;
      padding-top: 7vw;

      @include breakpoint(max, $tablet) {
        padding-top: 14vw;
      }

      @include breakpoint(max, $mobile) {
        padding-left: 0;
      }

      .veritas {
        display: block;
        margin-bottom: 2vw;

        @include breakpoint(max, $mobile) {
          margin-bottom: 10vh;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .text {
        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 160%;
        width: 22vw;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
          width: 30vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 5vw;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .references {
    padding: 10vh 8vw 15vh 8vw;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      padding: 4vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 15vw 5vw 0 5vw;

      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .sphereWrapper {
      position: relative;
      @include sizer(30vw);

      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $tablet) {
        @include sizer(40vw);
      }

      @include breakpoint(max, $mobile) {
        @include sizer(70vw);
      }

      .sphere,
      .sphereText {
        @include position(absolute, auto auto auto auto);
        will-change: transform;
        transform: translateZ(1px);
        backface-visibility: visible;
      }

      .sphere {
        @include sizer(75%);
      }

      .sphereText {
        @include sizer(90%);
        transform-origin: center;
      }
    }

    .title {
      color: #1a1818;
      font-family: Helonik;
      font-size: 3vw;
      font-weight: 400;
      letter-spacing: 0.01px;
      line-height: 135%;
      margin-bottom: 3vw;

      @include breakpoint(max, $tablet) {
        font-size: 3.25vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 10vw;
        margin-top: 5vh;
        text-align: center;
      }

      span {
        color: #00509d;
        font-family: AuxMono;
        letter-spacing: -0.25ch;
      }
    }
  }

  .referenceLogos {
    padding: 10vh 10vw;

    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include breakpoint(max, $mobile) {
        flex-direction: column;

        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
      }

      .logoWrapper {
        @include sizer(17vw, 10vh);
        margin-bottom: 4vw;
        margin-right: 4vw;
        overflow: hidden;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        transform: translateZ(0);
        -webkit-transform: translateZ(0);

        @include breakpoint(max, $tablet) {
          @include sizer(17vw, 4.5vh);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(80%, 10vh);
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &::after {
          content: "";
          @include position(absolute, 0 0 0 0);
          @include sizer(100%);
          border: 1px solid #cae9ff;
          border-radius: 80px;
          transition: 0.2s all ease;
          pointer-events: none;
        }

        &:hover {
          &::after {
            transform: scale(0.9);
            border: 2px solid #00509d;
          }

          .companyLogo {
            transform: scale(0.9);
          }
        }

        .companyLogo {
          max-height: 40%;
          max-width: 85%;
        }
      }
    }
  }

  .achievements {
    padding: 8vh 8vw;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5vw;

    @include breakpoint(max, $mobile) {
      grid-template-columns: 1fr;
      padding: 4vh 4vw;
    }
  }

  .feedbacks {
    @include sizer(100vw, auto);
    overflow: hidden;
    padding: 8vh 8vw;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(max, $mobile) {
      padding: 10vh 5vw;
    }
  }

  .question {
    padding: 10vh 9vw 20vh 9vw;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include breakpoint(max, $mobile) {
      padding: 10vh 5vw;

      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .tag {
      border: 1px solid #1a1818;
      border-radius: 91px;
      color: #1a1818;
      font-family: AuxMono;
      font-size: 1vw;
      letter-spacing: -0.25ch;
      padding: 1rem 2rem;

      @include breakpoint(max, $tablet) {
        font-size: 2vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
        padding: 1rem 2rem;
      }
    }

    .mail {
      color: #1a1818;
      font-family: Helonik;
      font-weight: 400;
      font-size: 2.75vw;
      line-height: 135%;
      width: 38vw;

      @include breakpoint(max, $tablet) {
        font-size: 3vw;
        width: 45vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 7vw;
        margin-top: 5vh;
        text-align: center;
        width: 100%;
      }

      .link {
        color: #00509d;
        font-family: AuxMono;
        letter-spacing: -0.2ch;
        text-decoration: underline;
      }
    }
  }

  .contact {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
      @include position(absolute, auto 0 -2px 0);
      @include sizer(100%, 50%);
      background: #002855;
      transform-origin: 100% 100%;

      z-index: 50;
    }
  }
}
