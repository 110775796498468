@import "gerillass";
@import "./_variables";

.header {
  $headerHeight: 15vh;
  $headerHeightMobile: 100px;

  @include sizer(100vw, $headerHeight);
  background: #ffffff;
  padding: 0 5vw;
  position: relative;
  z-index: 50000;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include breakpoint(max, $mobile) {
    @include sizer(100vw, $headerHeightMobile);
  }

  &::after {
    content: "";
    @include position(absolute, auto 0 0 50%);
    @include sizer(96%, 1px);
    transform: translateX(-50%);
    background: #1a1818;
    opacity: 0.1;
  }

  .navigation {
    padding-left: 9.5vw;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $tablet) {
      @include position(absolute, $headerHeight 0 0 0);
      @include sizer(100vw, 100vh);
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 0;
      padding-left: 4vw;
      padding-top: 4vw;
      transition: 0.2s all ease-in-out;
      transition-delay: 0.3s;
      visibility: hidden;

      &.open {
        opacity: 1;
        visibility: visible;
      }
    }

    @include breakpoint(max, $mobile) {
      @include position(absolute, $headerHeightMobile 0 0 0);
      padding-top: 10vw;
    }

    

    .navItem {
      align-items: center;
      cursor: pointer;
      justify-content: center;
      display: flex;
      margin-right: 0.5vw;
      padding: 0.5rem 1.5vw;
      position: relative;

      @include breakpoint(min, $tablet) {
        &.border {
          &:hover {
            &::after {
              clip-path: inset(0);
            }
          }
        }
      }

      @include breakpoint(max, $tablet) {
        flex-shrink: 0;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        margin: 2vw 0 2vw 1vw;
        padding: 0;
      }

      @include breakpoint(max, $mobile) {
        margin: 2.5vw 0 2.5vw 1vw;
      }

      * {
        cursor: pointer;
      }

      &:last-child {
        margin-left: 120px;
      }
      @media screen and (max-width: 1400px) {
        &:last-child {
          margin-left: 100px
        }
      }
      @media screen and (max-width: 1300px) {
        &:last-child {
          margin-left: 90px
        }
      }
      @media screen and (max-width: 1200px) {
        &:last-child {
          margin-left: 80px
        }
      }
      @media screen and (max-width: $tabletPlusOne) {
        &:last-child {

          margin-left: 0
        }
      }
      &::after {
        content: "";
        @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        border: 1px solid #00000050;
        border-radius: 32px;
        transform-origin: 0 50%;
        transition: 0.4s all ease-in-out;
        clip-path: inset(0 100% 0 0);

        @include breakpoint(max, $tablet) {
          display: none;
        }
      }

      .itemWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .item {
          color: #1a1818;
          font-family: "Helonik";
          font-size: 1vw;
          font-weight: 400;
          line-height: 100%;

          @include breakpoint(max, $tablet) {
            color: #ffffff;
            font-size: 4vw;
            width: 30vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 8vw;
            margin-bottom: 3vw;
            width: 55vw;
          }
        }

        .arrowWrapper {
          margin-left: 0.75vw;

          .arrow {
            @include sizer(6px, auto);
            display: inline-block;
            transform: rotate(90deg);
            transition: 0.4s all ease-in-out;

            &.rotate {
              transform: rotate(90deg);
            }

            @include breakpoint(max, $tablet) {
              @include sizer(3vw);
              transform: rotate(45deg);
              margin-top: 1vw;
              margin-left: 1vw;
            }

            @include breakpoint(max, $mobile) {
              @include sizer(6vw);
            }
          }
        }
      }
    }

    .menu {
      $pTop: 8vh;
      @include position(absolute, 100% auto auto 0);
      display: flex;
      padding-top: $pTop;

      @include breakpoint(max, $tablet) {
        @include position(relative, auto auto auto auto);
        padding-top: 0;
        display: flex;
        flex-direction: column;
      }

      &::before {
        content: "";
        @include position(absolute, auto 0 0 0);
        @include sizer(100%, calc(100% - $pTop));
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 5px 10px 15px #26292c52;
        transition: 0.4s max-width ease-in-out;

        @include breakpoint(max, $tablet) {
          display: none;
        }
      }

      &.veritas {
        $menuWidth: 14.5vw;
        $menuExpandedWidth: calc($menuWidth * 2.25);
        width: $menuWidth;

        &::before {
          max-width: $menuWidth;
          width: $menuExpandedWidth;
        }

        &.subOpen {
          &::before {
            max-width: $menuExpandedWidth;
          }
        }
      }

      &.solutions {
        $menuWidth: 22vw;
        $menuExpandedWidth: calc($menuWidth * 2);
        width: $menuWidth;

        @include breakpoint(max, $tablet) {
          width: 100%;
        }

        &::before {
          max-width: $menuWidth;
          width: $menuExpandedWidth;
        }

        &.subOpen {
          &::before {
            max-width: $menuExpandedWidth;
          }
        }
      }

      &.services {
        $menuWidth: 23.5vw;
        width: $menuWidth;

        @include breakpoint(max, $tablet) {
          width: 100%;
        }
      }

      &.subOpen {
        .menuItems {
          &::after {
            transform: translateY(-50%) scaleY(1);
          }
        }
      }

      .menuItems {
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding: 1.25rem 2rem;
        position: relative;
        z-index: 100;

        @include breakpoint(max, $tablet) {
          background: transparent;
          border: none;
        }

        @include breakpoint(max, $mobile) {
          padding: 1rem 0;
        }

        &::before {
          content: "";
          @include position(absolute, 50% 0 0 0);
          @include sizer(100%, 80%);
          background: #ffffff;
          transform: translateY(-50%);

          @include breakpoint(max, $tablet) {
            display: none;
          }
        }

        &::after {
          content: "";
          @include position(absolute, 50% 0 0 auto);
          @include sizer(1px, 80%);
          background: #000000;
          border-right: 1px solid #000000;
          transform: translateY(-50%) scaleY(0);
          transform-origin: 100% 100%;
          transition: 1s all ease;

          @include breakpoint(max, $tablet) {
            display: none;
          }
        }

        .menuItem {
          opacity: 1;
          transition: 0.4s opacity ease-in-out;
          width: 100%;
          // width: calc($menuWidth - 2.5vw);

          &.fade {
            opacity: 0.2;
          }

          &:last-child {
            .link {
              &::after {
                display: none;
              }
            }
          }

          .link {
            align-items: center;
            display: flex;
            margin: 0 8px;
            padding: 20px 0;
            position: relative;
            transition: 0.3s all ease-in-out;
            white-space: nowrap;
            will-change: transform;

            // @include breakpoint(max, $tablet) {
            //   &.activeMobile {
            //     text-decoration: underline;
            //     color: #ffffff;
            //   }
            // }

            @include breakpoint(max, $mobile) {
              padding: 10px 0;
            }

            &::after {
              content: "";
              @include position(absolute, auto 0 0 0);
              @include sizer(100%, 1px);
              background: #efefef;

              @include breakpoint(max, $tablet) {
                display: none;
              }
            }

            &:hover {
              transform: translateX(0.5rem);
            }

            .arrowMenu {
              @include sizer(6px, auto);
              flex-shrink: 0;
              margin-right: 0.5rem;

              @include breakpoint(max, $tablet) {
                display: none;
              }
            }

            .text {
              display: block;
              color: #1a1818;
              font-family: "Helonik";
              font-size: 1.2vw;
              font-weight: 300;
              line-height: 100%;
              text-align: left;

              @include breakpoint(max, $tablet) {
                color: #ffffff;
                font-size: 2.5vw;
              }

              @include breakpoint(max, $mobile) {
                font-size: 5.5vw;
              }
            }
          }
        }
      }

      .submenu {
        @include position(absolute, 0 0 0 100%);
        @include sizer(20vw, calc(100% - $pTop));
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        z-index: -10;

        @include breakpoint(max, $tablet) {
          @include position(relative, auto auto auto auto);
          z-index: 150;
        }

        .submenuItems {
          padding: 1.5rem 2rem;
          display: flex;
          flex-direction: column;

          @include breakpoint(max, $tablet) {
            background: transparent;
            border: none;
            padding: 1rem 1.5rem;
          }

          @include breakpoint(max, $mobile) {
            padding: 0.5rem 1rem;
          }

          .submenuItem {
            opacity: 1;
            transition: 0.4s all ease;
            width: 100%;

            &.fade {
              opacity: 0.2;
            }

            .linkSubmenu {
              align-items: center;
              cursor: pointer;
              display: flex;
              margin: 0 0.5rem;
              padding: 0.75rem 0;
              transition: 0.4s all ease;
              white-space: nowrap;

              &:hover {
                transform: translateX(1rem);
              }

              * {
                cursor: pointer;
              }

              .textSubmenu {
                display: block;
                color: #1a1818;
                font-family: "Helonik";
                font-size: 1vw;
                font-weight: 400;
                line-height: 100%;
                text-align: left;

                @include breakpoint(max, $tablet) {
                  font-size: 2.25vw;
                  color: #ffffff;
                }

                @include breakpoint(max, $mobile) {
                  font-size: 5vw;
                }
              }
            }
          }
        }
      }
    }
  }

  .btnWrapper {
    margin-left: auto;
    position: relative;

    .hamburger {
      @include sizer(7vw);
      background: #002855;
      border-radius: 50%;
      color: #ffffff;
      padding: 1rem;
      z-index: 100;

      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $mobile) {
        @include sizer(12vw);
        padding: 0.5rem;
      }

      .icon {
        @include sizer(40px);
        display: block;

        @include breakpoint(max, $mobile) {
          @include sizer(120px);
        }
      }
    }

    .bgWrapper {
      @include position(fixed, 0 0 0 0);
      @include sizer(100vw, 100vh);
      overflow: hidden;
      z-index: -1;
      pointer-events: none;

      .bg {
        $dimension: 5000px;

        @include position(
          absolute,
          calc($dimension / -2 + 12vw) calc($dimension / -2 + 10vw) auto auto
        );

        @include sizer($dimension);
        background: #002855;
        border-radius: 50%;
        pointer-events: none;
        transform: scale(0);
        transition: 0.8s all ease-in-out;
        z-index: -1;

        visibility: hidden;

        &.open {
          transform: scale(1);
          will-change: transform;
          z-index: -1;

          visibility: visible;
        }
      }
    }
  }
}

.logoWrapper {
  cursor: pointer;
  position: relative;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint(max, $tablet) {
    @include sizer(23vw, 4vw);
  }

  @include breakpoint(max, $mobile) {
    @include sizer(32vw, 7vw);
  }

  .logo {
    @include position(absolute, 0 0 0 0);
    transition: 0.2s all ease;
  }
}
