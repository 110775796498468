@import "gerillass";
@import "./_variables.scss";

.cardWrapper {
  @include sizer(100%, 60vh);
  background: #f8f8f8;
  border-radius: 10px;
  padding: 2vw 1.25vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(max, $tablet) {
    @include sizer(100%, 27vh);
  }

  @include breakpoint(max, $mobile) {
    @include sizer(100%, 50vh);
    border-radius: 4px;
    padding: 6vw 2vw;
  }

  .tag {
    background: transparent;
    border-radius: 91px;
    border: 1px solid #000000;
    color: #333333;
    display: block;
    font-family: Helonik;
    font-size: 1.15vw;
    font-weight: 400;
    line-height: 100%;
    margin-left: 1vw;
    padding: 1rem 2rem;
    white-space: nowrap;
    width: max-content;

    @include breakpoint(max, $tablet) {
      padding: 0.75rem 1.25rem;
    }

    @include breakpoint(max, $mobile) {
      font-size: 4vw;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;

    .animatedArea {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .iconWrapper {
        @include sizer(10vw);
        flex-shrink: 0;
        margin-bottom: 1rem;

        @include breakpoint(max, $mobile) {
          @include sizer(25vw);
        }

        .icon {
          @include sizer(auto, 100%);
          object-fit: contain;
        }
      }

      .oWrapper {
        overflow: hidden;
        height: 50%;

        // outline: 1px dashed red;
        // outline-offset: -1px;
      }

      .numbers {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // outline: 1px dashed blue;
        // outline-offset: -1px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;

          // outline: 1px dashed orangered;
          // outline-offset: -1px;

          .digit {
            color: #333333;
            font-family: AuxMono;
            font-size: 6vw;
            letter-spacing: -0.25ch;
            line-height: 100%;
            transform: translate(-0.125ch, 4px);
            will-change: transform;

            // outline: 1px dashed green;
            // outline-offset: -1px;

            @include breakpoint(max, $mobile) {
              font-size: 15vw;
            }

            .dk {
              font-size: 3.5vw;
              letter-spacing: -0.25ch;
            }
          }
        }
      }
    }

    .description {
      border-top: 1px solid #333333;
      padding-top: 1rem;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      @include breakpoint(max, $mobile) {
        padding-top: 0.5rem;
      }

      .asterisk {
        color: #1a1818;
        font-family: AuxMono;
        font-size: 4vw;

        flex-shrink: 0;

        @include breakpoint(max, $mobile) {
          font-size: 12vw;
        }
      }

      .text {
        color: #1a1818;
        font-family: Helonik;
        font-size: 1.5vw;
        font-weight: 400;
        line-height: 150%;
        width: 60%;

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
          width: 70%;
        }
      }
    }
  }
}
