@import "gerillass";
@import "./_variables";

.carousel {
  @include sizer(100vw, 50vh);

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @include breakpoint(max, $tablet) {
    @include sizer(auto, 20vh);
  }

  @include breakpoint(max, $mobile) {
    @include sizer(auto, 65vh);
  }

  .quoteWrapper {
    padding: 0 4vw;
    position: relative;
    width: 62vw;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $mobile) {
      width: 100%;
      padding: 0 8vw;
    }

    .quote {
      color: #1a1818;
      font-family: Helonik;
      font-size: 1.75vw;
      font-weight: 300;
      line-height: 160%;

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
        text-align: center;
      }

      .quoteChar {
        z-index: 10;

        display: inline-block;
      }
    }

    // .qmLeft,
    // .qmRight {
    //   // position: absolute;

    //   @include breakpoint(max, $tablet) {
    //     @include sizer(2.5vw);
    //   }

    //   @include breakpoint(max, $mobile) {
    //     @include sizer(7vw);
    //   }
    // }

    // .qmLeft {
    //   padding-bottom: 2vw;
    //   padding-right: 2vw;
    // }

    // .qmRight {
    //   padding-left: 2vw;
    //   padding-top: 2vw;
    // }
  }

  .controls {
    border-radius: 12px;
    border: 1px solid #00519d3b;
    overflow: hidden;
    padding: 1rem 2rem;
    position: relative;
    width: 70vw;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint(max, $tablet) {
      padding: 0.5rem 1rem;
    }

    @include breakpoint(max, $mobile) {
      border: none;
      border-radius: 0;
      width: 100%;
      padding: 0;
    }

    .indicator {
      @include position(absolute, 1rem 1rem auto auto);

      @include breakpoint(max, $tablet) {
        @include position(absolute, 0.5rem 0.5rem auto auto);
      }

      @include breakpoint(max, $mobile) {
        @include position(absolute, 0 0 auto auto);
      }

      .text {
        color: #1a1818;
        font-family: Helonik;
        font-size: 1.5vw;
        font-weight: 300;

        @include breakpoint(max, $mobile) {
          font-size: 3.5vw;
        }
      }
    }

    .buttonWrapper {
      border-right: 1px solid rgba(128, 128, 128, 0.2);
      padding-right: 1rem;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .btn {
        background: none;
        border: none;
        cursor: pointer;
        padding: 2rem 3rem;
        transition: 0.4s all ease;

        @include breakpoint(max, $tablet) {
          padding: 1rem 1.5rem;
        }

        @include breakpoint(max, $tabletMini) {
          padding: 0.5rem 1rem;
        }

        @include breakpoint(max, $mobile) {
          padding: 1rem 1.5rem;
        }

        &:nth-child(2) {
          border-top: 1px solid rgba(128, 128, 128, 0.2);
        }

        .arrow {
          @include sizer(1.25vw);

          @include breakpoint(max, $mobile) {
            @include sizer(3vw);
          }
        }
      }
    }

    .author {
      padding-left: 3rem;

      display: flex;

      @include breakpoint(max, $mobile) {
        padding-left: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
      }

      .imgWrapper {
        @include sizer(140px, 120px);

        @include breakpoint(max, $tablet) {
          @include sizer(70px, 60px);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(105px, 90px);
          margin-bottom: 2vw;
        }

        .img {
          @include sizer(100%);
          object-fit: cover;
        }
      }

      .info {
        padding-left: 2rem;
        margin-top: auto;

        @include breakpoint(max, $mobile) {
          padding-left: 0;
        }

        .personName {
          color: #1a1818;
          font-family: Helonik;
          font-size: 1.75vw;
          font-weight: 400;
          line-height: 140%;

          @include breakpoint(max, $mobile) {
            font-size: 4.5vw;
          }
        }

        .personTitle {
          color: #1a1818;
          font-family: Helonik;
          font-size: 1.25vw;
          font-weight: 200;
          line-height: 140%;

          @include breakpoint(max, $mobile) {
            font-size: 3vw;
          }
        }
      }
    }
  }
}
