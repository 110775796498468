@import "gerillass";
@import "./_variables";

.footer {
  background: #002855;
  padding: 0 8vw;
  position: relative;
  transform: translateZ(-1000px);

  &::before {
    content: "";
    @include position(absolute, -5px 0 0);
    @include sizer(100%, 10px);
    background: #002855;
    z-index: -1;
  }

  @include breakpoint(max, $tablet) {
    font-size: 2vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 0;
  }

  .col {
    color: #ffffff;
    font-family: AuxMono;
    margin-right: 5vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint(max, $tablet) {
      margin-right: 2.8vw;
      margin-left: 2vw;
      width: 15vw;
    }

    @include breakpoint(max, $mobile) {
      width: 35vw;
      margin-bottom: 5vh;
      margin-right: 10vw;
    }

    &.email {
      margin-left: 4vw;

      @include breakpoint(max, $tablet) {
        margin-left: 2vw;
      }

      @include breakpoint(max, $mobile) {
        margin-left: 10vw;
      }
    }

    &.email,
    &.phone {
      white-space: nowrap;
    }

    .title {
      font-size: 14px;
      margin-bottom: 1.5rem;
      opacity: 0.9;
      text-decoration: underline;

      @include breakpoint(max, $tabletMini) {
        font-size: 10px;
      }

      @include breakpoint(max, $mobile) {
        font-size: 12px;
        margin-bottom: 0.5rem;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .link {
        color: #5996d1;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: -0.1ch;
        line-height: 160%;
        margin-bottom: 1rem;
        white-space: nowrap;

        @include breakpoint(max, $tablet) {
          white-space: inherit;
        }

        @include breakpoint(max, $tabletMini) {
          font-size: 10px;
        }

        @include breakpoint(max, $mobile) {
          font-size: 11px;
          line-height: 200%;
        }
      }

      .map {
        color: #ffffff;
        font-family: AuxMono;
        letter-spacing: -1.25px;
        text-decoration: underline;

        @include breakpoint(max, $tablet) {
          font-size: 12px;
        }
      }
    }
  }

  .top {
    display: flex;
    padding: 15vh 4vw 5vh 4vw;

    @include breakpoint(max, $tablet) {
      flex-direction: column;
      padding: 7vh 3vw 5vh 3vw;
    }

    @include breakpoint(max, $mobile) {
      align-items: center;
      flex-direction: column;
      padding: 10vh 2vw 3vh 2vw;
    }

    .left {
      margin-right: 4vw;

      @include breakpoint(max, $tablet) {
        margin-bottom: 10vw;
      }

      @include breakpoint(max, $mobile) {
        margin-bottom: 10vh;
        margin-right: 15vw;
      }

      .logoWrapper {
        @include sizer(13vw, auto);
        cursor: pointer;

        @include breakpoint(max, $tablet) {
          @include sizer(22vw, auto);
          margin-right: 5vw;
        }

        @include breakpoint(max, $mobile) {
          @include sizer(60vw, auto);
        }

        .logo {
          @include sizer(100%);
          display: block;
        }
      }
    }

    .right {
      display: flex;
      width: 100%;

      @include breakpoint(max, $tablet) {
        margin-left: 0;
      }

      @include breakpoint(max, $mobile) {
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 0;

        .link {
          white-space: nowrap;
        }
      }
    }
  }

  .middle {
    //border-top: 1px solid #ffffff31;
    display: flex;
    padding: 4vh 4vw 7vh 4vw;
    position: relative;

    @include breakpoint(max, $tablet) {
      padding: 3vw 3vw 5vw 3vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 10vh 4vw 5vh 4vw;
      flex-direction: column;
    }

    &::before {
      content: "";
      @include position(absolute, 0 0 auto 50%);
      @include sizer(90%, 1px);
      background: #20476f;
      transform: translateX(-50%);
    }

    .left {
      margin-right: 3vw;

      @include breakpoint(max, $tablet) {
        margin-right: 15vw;
      }

      @include breakpoint(max, $mobile) {
        margin-bottom: 10vh;
      }

      .addressWrapper {
        @include breakpoint(max, $tablet) {
          width: 35vw;
        }

        @include breakpoint(max, $mobile) {
          width: 70vw;
        }
      }
    }

    .right {
      display: flex;

      @include breakpoint(max, $tablet) {
        margin-left: auto;
      }

      @include breakpoint(max, $mobile) {
        margin-left: 0;
      }
    }

    .socialMobile {
      padding-left: 2vw;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include breakpoint(min, $mobile) {
        display: none;
      }
    }
  }

  .bottom {
    background: #edf6f9;
    @include border-radius(top, 32px);
    padding: 1.75vw 4vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(max, $tablet) {
      padding: 2.75vw 3vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 3vh 4vw 2vh 4vw;
    }

    .copyright {
      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .cText {
        color: #002855;
        font-family: AuxMono;
        font-size: 12px;
        letter-spacing: -0.2ch;
        line-height: 100%;

        display: flex;
        align-items: center;

        @include breakpoint(max, $mobile) {
          flex-direction: column;
          font-size: 12px;
          line-height: 240%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          width: 70%;

          .seperator {
            display: none;
          }

          .link {
            margin-top: 3vh;
          }
        }

        .seperator {
          padding: 0 0.5vw;
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(max, $mobile) {
        display: none;
      }

      .icon {
        padding-right: 3rem;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
