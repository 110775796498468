@import "gerillass";
@import "./variables";

.heroVeritas {
  background: #ffffff;
  padding: 10vh 5vw 5vh 5vw;
  position: relative;
  z-index: 100;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 2.5vh 5vw;
  }

  .top {
    .left {
      margin-top: 5vh;
      width: 42vw;

      @include breakpoint(max, $tablet) {
        width: 60vw;
      }

      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .iconWrapper {
        @include sizer(6vw, auto);
        margin-bottom: 3vh;

        @include breakpoint(max, $tablet) {
          @include sizer(15vw, auto);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(30vw, auto);
        }

        .icon {
          @include sizer(100%);
          display: block;
          object-fit: contain;
        }
      }

      .title {
        color: #1a1818;
        font-family: Helonik;
        font-size: 3vw;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 5vh;
        text-align: left;

        @include breakpoint(max, $tablet) {
          font-size: 5vw;
          width: 55vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 8vw;
          width: 100%;
        }

        &.titleL {
          font-size: 4.5vw;

          @include breakpoint(max, $tablet) {
            font-size: 6vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 8vw;
          }
        }
      }

      .subTitle {
        color: #1a1818;
        font-family: Helonik;
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 160%;
        text-align: left;
        width: 30vw;

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
          width: 50vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4.5vw;
          width: 80vw;
        }
      }
    }

    .lottieWrapper {
      @include position(absolute, 0 0 0 auto);
      @include sizer(auto, 100%);
      padding-top: 5vh;
      z-index: -1;

      display: flex;
      justify-content: flex-end;

      @include breakpoint(max, $tablet) {
        @include sizer(auto, 100%);
        padding-top: 0vh;
      }

      @include breakpoint(max, $mobile) {
        display: none;
      }

      .lottie {
        @include sizer(70vw, 100%);
        flex-shrink: 0;

        @include breakpoint(max, $tablet) {
          @include sizer(80vw, 40vw);
        }
      }
    }
  }
}
