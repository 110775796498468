@import "gerillass";
@import "./variables";

.breadcrumb {
  border: 1px solid #0466c8;
  border-radius: 64px;
  color: #0466c8;
  font-family: "AuxMono";
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.2ch;
  min-width: 13vw;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-align: left;
  transform: translateX(-1.5rem);
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(max, $tablet) {
    //border: none;
    font-size: 2vw;
    margin-bottom: 3vh;
    padding: 0.75rem 1.5rem;
    transform: translateX(-0.75rem);
  }

  @include breakpoint(max, $mobile) {
    border: none;
    font-size: 3.5vw;
    margin-bottom: 2vh;
    padding: 0;
    transform: translateX(0);
  }

  .seperator {
    padding: 0 1rem;

    @include breakpoint(max, $mobile) {
      padding: 0 0.5rem;
    }
  }
}
