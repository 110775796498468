@import "gerillass";
@import "./variables";

.blog {
  background: #ffffff;
  padding: 10vh 5vw;

  .title {
    color: #1a1818;
    font-family: "Helonik";
    font-size: 4vw;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 5vh;
    text-align: left;

    @include breakpoint(max, $tablet) {
      font-size: 8vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 12vw;
    }
  }

  .content {
    .search {
      margin: 10vh 0 8vh 0;

      display: flex;
      align-items: center;

      .inputWrapper {
        @include sizer(40vw, 4rem);
        border-bottom: 1px solid #000000;
        position: relative;

        color: #1a1818;
        font-family: "Helonik";
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 100%;
        text-align: left;

        @include breakpoint(max, $tablet) {
          @include sizer(50vw, 3.5rem);
          font-size: 1.25rem;
        }

        @include breakpoint(max, $mobile) {
          @include sizer(100%, 3.5rem);
        }

        .input {
          @include position(absolute, 0 0 0 0);
          @include sizer(100%);
          border: none;
          outline: none;
        }

        .label {
          @include position(absolute, 0 0 0 0);
          @include sizer(100%);
          pointer-events: none;
          display: flex;
          align-items: center;
          background: transparent;
        }

        .iconWrapper {
          @include position(absolute, 0 0 0 auto);
          @include sizer(1.25rem);
          margin: auto 0;
        }
      }

      .cleanForm {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all ease;

        opacity: 1;
        visibility: visible;

        &.disabled {
          opacity: 0;
          visibility: hidden;
        }

        .text {
          color: #1a1818;
          font-family: "Helonik";
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 100%;
          text-align: left;
        }
      }
    }

    .blogs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .blogCard {
        cursor: pointer;
        margin-bottom: 10vh;

        display: flex;

        * {
          cursor: pointer;
        }

        @include breakpoint(max, $tablet) {
          flex-direction: column;
          margin-bottom: 0;
        }

        .imgWrapper {
          @include sizer(25vw, 25vh);
          border-radius: 4px;
          overflow: hidden;

          @include breakpoint(max, $tablet) {
            @include sizer(42vw, 18vh);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(100%, 32vh);
          }

          .img {
            @include sizer(100%);
            display: block;
            object-fit: cover;
          }
        }

        .text {
          @include sizer(17vw, 30vh);
          margin-left: 1.5rem;
          padding: 1vh 0 8vh 0;

          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @include breakpoint(max, $tablet) {
            @include sizer(42vw, 20vh);
            margin-left: 0;
          }

          @include breakpoint(max, $mobile) {
            @include sizer(100%, 30vh);
          }

          .blogTitle {
            color: #1a1818;
            font-family: "Helonik";
            font-size: 1.75vw;
            font-weight: 400;
            line-height: 120%;
            text-align: left;

            @include breakpoint(max, $tablet) {
              font-size: 2.5vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 5vw;
              font-weight: 500;
              line-height: 180%;
            }
          }

          .btnWrapper {
            margin-top: auto;
            height: fit-content;
          }
        }
      }

      .notFound {
        @include sizer(100%, 50vh);

        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          color: #1a1818;
          font-family: "Helonik";
          font-size: 4vw;
          font-weight: 700;
          line-height: 100%;
          text-align: left;
        }
      }
    }
  }
}
