@import "gerillass";
@import "../scss/variables.scss";

.orderedGrid {
  background: #ffffff;
  overflow: hidden;
  padding: 10vh 0;
  position: relative;
  z-index: 100;

  @include breakpoint(max, $tablet) {
    padding: 5vh 0;
  }

  .bgWrapper {
    @include position(absolute, 5% 0 0 auto);
    @include sizer(80%);

    .bg {
      @include sizer(100%);
      display: block;
      object-fit: contain;
    }
  }

  .pre {
    display: flex;
    margin-bottom: 15vh;
    margin-left: auto;
    margin-right: 8vw;
    width: fit-content;

    @include breakpoint(max, $tablet) {
      margin-bottom: 7vh;
      margin-right: 5vw;
    }

    @include breakpoint(max, $mobile) {
      flex-direction: column-reverse;
      margin-bottom: 5vh;
      padding: 0 5vw;
      width: 100%;
    }

    &.leftAligned {
      margin-left: 9.5vw;
      margin-right: auto;
      flex-direction: row-reverse;

      @include breakpoint(max, $tablet) {
        margin-left: 5vw;
      }

      @include breakpoint(max, $mobile) {
        flex-direction: column-reverse;
        margin-left: 0;
      }

      .arrow {
        transform: rotate(0deg);
        margin-left: 0;
        margin-right: 0;
      }

      .text {
        @include breakpoint(max, $mobile) {
          font-size: 7.5vw;
          text-align: left;
          width: 100%;
        }
      }
    }

    &.vertical {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;

      .text {
        margin-bottom: 3vh;
      }
    }

    .arrow {
      @include sizer(4.5vw);
      margin-top: auto;
      margin-bottom: 0.75vw;
      margin-right: 2rem;
      transform: rotate(90deg);

      @include breakpoint(max, $tablet) {
        @include sizer(7vw);
      }

      @include breakpoint(max, $mobile) {
        @include sizer(20vw);
        margin-left: auto;
        margin-right: 0;
        margin-top: 3vh;
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: cover;
      }
    }

    .text {
      color: #1a1818;
      font-family: "Helonik";
      font-size: 3vw;
      font-weight: 300;
      line-height: 130%;
      text-align: left;
      width: 32vw;

      @include breakpoint(max, $tablet) {
        font-size: 4vw;
        width: 45vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 6.5vw;
        text-align: right;
        width: 100%;
      }

      span {
        color: #0466c8;
        font-family: "AuxMono";
        letter-spacing: -0.2ch;
      }
    }
  }

  .itemsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .items {
      display: grid;

      .item {
        @include sizer(27vw, 44vh);
        background: #ffffff;
        padding: 4vh 3vw 6vh 6vh;
        position: relative;
        overflow: hidden;
        z-index: 100;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @include breakpoint(max, $tablet) {
          @include sizer(30vw, 25vh);
          padding: 2vw;
        }

        @include breakpoint(max, $mobile) {
          @include sizer(90vw, auto);
          padding: 10vw 0 2.5vw 0;
        }

        &.bg {
          &:nth-child(odd) {
            background: #f4f4f4;
          }
        }

        &::after {
          content: "";
          @include position(absolute, 0 0 0 0);
          @include sizer(100%);
          border: 1px solid #000000;
          z-index: -1;

          @include breakpoint(max, $mobile) {
            border: none;
            border-bottom: 1px solid #000000;
            opacity: 0.3;
          }
        }

        @include breakpoint(min, $mobile) {
          &.topRight {
            &::before {
              content: "";
              @include position(absolute, -65% -65% auto auto);
              @include sizer(100%);
              background: #ffffff;
              border-bottom: 1px solid #000000;
              z-index: 100;
              transform: rotate(45deg);
            }
          }

          &.bottomRight {
            &::before {
              content: "";
              @include position(absolute, auto -70% -70% auto);
              @include sizer(100%);
              background: #ffffff;
              border-left: 1px solid #000000;
              z-index: 100;
              transform: rotate(45deg);
            }
          }
        }

        .index {
          color: #1365b5;
          display: block;
          font-family: "AuxMono";
          font-size: 4.5vw;
          font-weight: 400;
          letter-spacing: -0.2ch;
          line-height: 100%;
          position: relative;
          text-align: left;
          transform: translateX(-0.2ch);
          z-index: 200;

          @include breakpoint(max, $tablet) {
            font-size: 5vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 15vw;
            margin-left: auto;
          }
        }

        .text {
          color: #1365b5;
          font-family: "Helonik";
          text-align: left;
          font-size: 1.25vw;
          font-weight: 400;
          line-height: 140%;
          position: relative;
          width: 90%;

          @include breakpoint(max, $tablet) {
            font-size: 2vw;
            opacity: 1;
            visibility: visible;
            width: 100%;
          }

          @include breakpoint(max, $mobile) {
            font-size: 3.75vw;
            line-height: 180%;
            text-align: right;
          }
        }
      }
    }
  }
}
