@import "gerillass";
@import "../scss/variables.scss";

.contactUs {
  background: #002855;
  padding: 15vh 10vw 10vh 10vw;
  position: relative;
  z-index: 0;

  @include breakpoint(max, $tablet) {
    padding: 10vh 7vw 10vh 7vw;
  }

  @include breakpoint(max, $mobile) {
    padding: 10vh 5vw 5vh 5vw;
  }

  &::after {
    content: "";
    @include position(absolute, 99% 0 0 50%);
    @include sizer(80%, 1px);
    background: #0466c8;
    transform: translateX(-50%);
    z-index: 20;
  }

  .text {
    color: #ffffff;
    font-family: Helonik;
    font-size: 2.7vw;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    width: 55vw;

    @include breakpoint(max, $tablet) {
      font-size: 3.5vw;
      line-height: 160%;
      width: 80vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 6vw;
      line-height: 160%;
      width: 100%;
      text-align: center;
    }
  }

  .btnWrapper {
    display: block;
    margin-top: 10vh;
    width: max-content;

    @include breakpoint(max, $tablet) {
      margin-top: 7vh;
    }

    @include breakpoint(max, $mobile) {
      margin: 10vh auto 5vh auto;
    }
  }
}
