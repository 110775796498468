@import "gerillass";
@import "./_variables";

.animatedLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .oWrapper1 {
    display: block;
    margin-bottom: 0.25rem;
    overflow: hidden;
    position: relative;

    @include sizer(9vw, 2vw);

    .logo {
      z-index: 10;
    }
  }

  .oWrapper2 {
    display: block;
    overflow: hidden;
    position: relative;

    @include sizer(7vw, 1vw);

    .infoTech {
      width: 7vw;
      will-change: transform;
    }
  }
}
