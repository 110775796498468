.slick-arrow {
  display: none;
}

.slick-dots {
  li {
    transform: scale(0.5);
    transition: 0.4s all ease;

    button {
      &::before {
        content: "";
        background: #0466c8;
        border-radius: 50%;
        color: transparent;
      }
    }
  }

  .slick-active {
    transform: scale(1);
  }
}
