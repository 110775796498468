@import "gerillass";
@import "./variables";

.footerNav {
  background: #002855;
  padding: 10vh 8vw;
  position: relative;
  z-index: 10;

  @include breakpoint(max, $mobile) {
    padding: 10vh 4vw;
  }

  &::after {
    content: "";
    @include position(absolute, -2.5px 0 0 auto);
    @include sizer(100%, 5px);
    background: #002855;
  }

  .navTitle {
    color: #ffffff;
    font-family: "Helonik";
    font-size: 2.1vw;
    font-weight: 400;
    line-height: 140%;
    text-align: left;
    margin-bottom: 4vw;
    width: 24vw;

    @include breakpoint(max, $tablet) {
      font-size: 4vw;
      width: 40vw;
    }

    @include breakpoint(max, $mobile) {
      font-size: 7vw;
      width: 80vw;
    }
  }

  .nav {
    .navItemWrapper {
      position: relative;

      &::after {
        content: "";
        @include position(absolute, auto 0 0 0);
        @include sizer(100%, 1px);
        margin: 0 auto;
        background: #ffffff;
        opacity: 0.4;
      }

      .navItem {
        cursor: pointer;
        padding: 1rem 0 0.5rem 0;
        position: relative;

        display: flex;
        align-items: center;

        &.active {
          opacity: 0.2;
          pointer-events: none;
        }

        * {
          cursor: pointer;
        }

        @include breakpoint(max, $tablet) {
          padding: 1.25rem 0 0.75rem 0;
        }

        &:hover {
          .text {
            .par {
              transform: translateX(1.5rem);

              @include breakpoint(max, $tablet) {
                transform: translateX(0);
              }
            }
          }

          .arrow {
            transform: rotate(0deg);

            @include breakpoint(max, $tablet) {
              transform: rotate(-45deg);
            }
          }
        }

        .text {
          display: flex;

          .itemIndex {
            color: #ffffff;
            font-family: "AuxMono";
            font-size: 2vw;
            font-weight: 400;
            letter-spacing: -0.2ch;
            line-height: 120%;
            margin-right: 7vw;
            text-align: left;

            @include breakpoint(max, $tablet) {
              font-size: 3vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 3.5vw;
              margin-right: 5vw;
              margin-top: auto;
            }
          }

          .par {
            color: #ffffff;
            font-family: "Helonik";
            font-size: 1.75vw;
            font-weight: 300;
            line-height: 120%;
            text-align: left;
            transition: 0.6s all ease;

            @include breakpoint(max, $tablet) {
              font-size: 3vw;
            }

            @include breakpoint(max, $mobile) {
              font-size: 4vw;
            }
          }
        }

        .arrow {
          @include sizer(2rem);
          margin-left: auto;
          transform: rotate(-45deg);
          transition: 0.6s all ease;

          @include breakpoint(max, $tablet) {
            @include sizer(1.5rem);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(1rem);
          }
        }
      }
    }
  }
}
