@import "gerillass";
@import "./variables";

.infoTable {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10vh 5vw;
  position: relative;
  z-index: 100;

  @include breakpoint(max, $tablet) {
    padding: 5vh 5vw 10vh 5vw;
  }

  .col {
    width: 17vw;

    @include breakpoint(max, $tablet) {
      width: 100%;

      &:first-child {
        .titleWrapper {
          margin-top: 5vh;
        }
      }
    }

    .titleWrapper {
      @include sizer(100%, 15vh);
      display: flex;
      align-items: flex-end;
      position: relative;

      @include breakpoint(max, $tablet) {
        @include sizer(100%, auto);
        margin-top: 5vh;
      }

      @include breakpoint(max, $mobile) {
        margin-top: 5vh;
      }

      .title {
        @include sizer(100%, auto);
        color: #333333;
        font-family: AuxMono;
        font-size: 2.5vw;
        font-weight: 400;
        letter-spacing: -0.2ch;
        line-height: 140%;
        margin-bottom: 2vh;
        position: relative;
        text-align: left;
        transform: translateX(-0.1ch);

        @include breakpoint(max, $tablet) {
          font-size: 3.5vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 7vw;
        }

        &::after {
          content: "";
          @include position(absolute, auto 0 0 0);
          @include sizer(100%, 1px);
          background: #b84452;

          @include breakpoint(max, $tablet) {
            @include sizer(44%, 1px);
          }

          @include breakpoint(max, $mobile) {
            @include sizer(100%, 1px);
          }
        }
      }

      .arrowWrapper {
        @include position(absolute, 50% 0 0 auto);
        transform: translateY(-50%);

        .arrow {
          @include sizer(5vw);
          display: block;
          transform-origin: center;
          transition: 0.4s all ease-in-out;

          &.open {
            transform: rotate(45deg);
          }

          @include breakpoint(min, $mobile) {
            display: none;
          }
        }
      }
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;

      @include breakpoint(max, $tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include breakpoint(max, $mobile) {
        max-height: 0;
        overflow: hidden;
        transition: 0.8s all ease-in-out;

        &.open {
          max-height: 100vh;
        }
      }

      .info {
        border-bottom: 1px solid #bc465427;
        cursor: pointer;
        display: block;
        font-family: Helonik;
        font-weight: 400;
        line-height: 140%;
        padding: 4vh 0 3vh 0;
        position: relative;
        text-align: left;
        transition: 0.4s all ease-in-out;

        &::before {
          content: "";
          @include position(absolute, 50% 0 0 50%);
          @include sizer(107%, 95%);
          background: #f5f5f5;
          border-radius: 6px;
          transform: translate(-50%, -50%);
          transition: 0.4s all ease;
          z-index: -1;

          visibility: hidden;
          opacity: 0;

          @include breakpoint(max, $tablet) {
            @include sizer(104%, 97%);
          }
        }

        @include breakpoint(min, $tabletPlusOne) {
          &:last-child {
            border-bottom: none;
          }

          @include breakpoint(min, $tabletPlusOne) {
            &:hover {
              &::before {
                visibility: visible;
                opacity: 1;
              }

              .infoTitleWrapper {
                transform: translateX(1rem);
              }
            }
          }
        }

        @include breakpoint(max, $tablet) {
          padding: 2.5vh 0 1vh 0;
          width: 40vw;
        }

        @include breakpoint(max, $mobile) {
          padding: 1vh 0 3.5vh 0;
          width: 100%;
        }

        &.active {
          &::before {
            visibility: visible;
            opacity: 1;
          }
        }

        * {
          cursor: pointer;
        }

        .infoTitleWrapper {
          margin-bottom: 2vh;
          transition: 0.4s all ease-in-out;

          display: flex;

          @include breakpoint(max, $tablet) {
            margin-bottom: 2vh;
          }

          .iconWrapper {
            @include sizer(6px, auto);
            margin-right: 0.5rem;

            @include breakpoint(max, $tablet) {
              @include sizer(10px, auto);
              display: none;
            }

            @include breakpoint(max, $mobile) {
              @include sizer(6px, auto);
            }
          }

          .infoTitle {
            color: #333333;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 140%;

            @include breakpoint(max, $tablet) {
              font-size: 1.5rem;
            }

            @include breakpoint(max, $mobile) {
              font-size: 1.1rem;
            }
          }
        }

        .infoText {
          color: #999999;
          font-size: 1rem;
          line-height: 140%;

          @include breakpoint(max, $tablet) {
            font-size: 1.25rem;
          }

          @include breakpoint(max, $mobile) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
