@import "gerillass";
@import "./_variables";

.form {
  background: #f8f8f8;
  border-radius: 40px;
  padding: 5vw 5vw 7vw 5vw;
  width: 90vw;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @include breakpoint(max, $mobile) {
    border-radius: 0;
    padding: 10vh 10vw;
    width: 100vw;
  }

  $mobileMarginB: 4vh;

  &.contactPage {
    background: transparent;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    width: 45vw;

    @include breakpoint(max, $tablet) {
      width: 90vw;
    }

    .row {
      align-items: flex-end;
      margin-bottom: 1.75vw;

      @include breakpoint(max, $tablet) {
        margin-bottom: 4vw;
      }

      @include breakpoint(max, $mobile) {
        align-items: center;
        margin-bottom: 5vw;
        width: 100%;
      }

      .text {
        font-size: 1.25vw;
        line-height: 100%;

        @include breakpoint(max, $tablet) {
          font-size: 2.5vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 7vw;
          line-height: 180%;
        }
      }

      .inputWrapper {
        @include sizer(15vw, 2vw);
        margin: 0 1rem;

        @include breakpoint(max, $tablet) {
          @include sizer(27vw, 3vw);
        }

        @include breakpoint(max, $mobile) {
          @include sizer(90%, 10vw);
          margin-bottom: 1rem;

          margin-right: 0;
          margin-left: 0;
          padding-right: 0;
        }

        &.tArea {
          width: 45vw;
          font-size: 1vw;

          @include breakpoint(max, $tablet) {
            width: 100%;
          }

          @include breakpoint(max, $mobile) {
            margin-top: 5vh;
          }
        }

        &.subject,
        &.tArea {
          margin-left: 0;

          @include breakpoint(max, $mobile) {
            width: 100%;
          }
        }

        .label,
        .input,
        .resizableTextarea {
          font-size: 1.25vw;

          @include breakpoint(max, $tablet) {
            font-size: 2.5vw;
          }

          @include breakpoint(max, $mobile) {
            font-size: 7vw;
          }
        }
      }
    }

    .confirmation {
      @include breakpoint(max, $tablet) {
        margin-top: 5vw;
      }

      @include breakpoint(max, $mobile) {
        width: 100%;
      }

      .text {
        font-size: 1vw;

        @include breakpoint(max, $tablet) {
          font-size: 2vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 4vw;
        }
      }
    }

    .btnWrapper {
      margin-top: -4vh;
      margin-left: auto;

      @include breakpoint(max, $tablet) {
        margin-top: 7vh;
        margin-left: 0;
        margin-right: auto;
      }

      @include breakpoint(max, $mobile) {
        margin-top: 10vh;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .row {
    margin-bottom: 2.5vw;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $tablet) {
      font-size: 2vw;
    }

    @include breakpoint(max, $mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .text {
      color: #1a1818;
      font-family: Helonik;
      font-weight: 300;
      font-size: 2.25vw;
      line-height: 125%;

      @include breakpoint(max, $mobile) {
        font-size: 6vw;
        line-height: 180%;
        margin-bottom: calc($mobileMarginB / 2);
        text-align: center;
      }
    }

    .inputWrapper {
      @include sizer(23vw, 2.5vw);
      margin: 0 2rem;
      position: relative;

      @include breakpoint(max, $mobile) {
        @include sizer(100%, 7vh);
        margin-bottom: $mobileMarginB;
      }

      &.tArea {
        height: auto;
        width: 100%;

        .label {
          padding-bottom: 0.5rem;
        }
      }

      .label {
        @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        color: #00509d;
        font-family: AuxMono;
        font-weight: 300;
        font-size: 1.75vw;
        letter-spacing: -0.2ch;
        line-height: 125%;
        pointer-events: none;
        transition: 0.3s all ease;

        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(max, $mobile) {
          font-size: 6vw;
        }

        &.labelSubject {
          justify-content: flex-start;

          .arrow {
            @include sizer(1rem, auto);
            margin-left: auto;
          }
        }
      }

      .input {
        @include position(absolute, 0 0 0 0);
        //@include sizer(100%);
        background: none;
        border: none;
        border-bottom: 1px solid #1a1818;
        margin: 0;
        padding: 0;
        outline: none;

        color: #1a1818;
        font-family: AuxMono;
        font-weight: 300;
        font-size: 1.75vw;
        letter-spacing: -0.2ch;
        line-height: 125%;
        text-align: center;

        -webkit-appearance: none; /*For Chrome*/
        -moz-appearance: none; /*For Mozilla*/
        appearance: none;
        border-radius: 0;

        @include breakpoint(max, $mobile) {
          font-size: 5vw;
        }
      }

      .resizableTextarea {
        // @include position(absolute, 0 0 0 0);
        @include sizer(100%);
        background: none;
        border: none;
        border-bottom: 1px solid #1a1818;
        margin: 0;
        padding: 0;
        position: relative;
        resize: none;
        outline: none;

        -webkit-appearance: none; /*For Chrome*/
        -moz-appearance: none; /*For Mozilla*/
        appearance: none;
        border-radius: 0;

        color: #1a1818;
        font-family: AuxMono;
        font-weight: 300;
        font-size: 1.75vw;
        line-height: 125%;
        text-align: center;

        @include breakpoint(max, $tablet) {
          font-size: 3vw;
        }

        @include breakpoint(max, $mobile) {
          font-size: 6vw;
        }
      }

      // &::after {
      //   content: "";
      //   @include position(absolute, auto 0 0 0);
      //   @include sizer(100%, 1px);
      //   background: #1a1818;
      // }
    }
  }

  .confirmation {
    cursor: pointer;
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .check {
      @include sizer(1.5rem);
      position: relative;
      border: 1px solid #1a1818;
      border-radius: 50%;
      flex-shrink: 0;

      @include breakpoint(max, $tablet) {
        @include sizer(1.25rem);
      }

      .dot {
        @include center;
        @include sizer(80%);
        background: #00509d;
        border-radius: 50%;
        position: absolute;
        transform-origin: center;
      }
    }

    .text {
      color: #1a1818;
      font-family: Helonik;
      font-weight: 300;
      font-size: 1.25vw;
      line-height: 125%;
      padding-left: 1rem;

      @include breakpoint(max, $mobile) {
        font-size: 3.5vw;
      }
    }
  }

  .btnWrapper {
    margin-top: 5vw;

    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(max, $mobile) {
      margin-top: 7vh;
    }
  }
}
