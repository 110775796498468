@import "gerillass";
@import "./_variables";

.pinned {
  @include sizer(100vw, 100vh);
  background: transparent;
  overflow: hidden;
  padding: 10vh 0;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include breakpoint(max, $tablet) {
    display: none;
  }

  .itemWrapper {
    @include sizer(85vw, 70vh);
    position: relative;
    // z-index: 5000;

    display: flex;

    @include breakpoint(max, $tablet) {
      @include sizer(90vw, 50vh);
    }

    @include breakpoint(max, $tabletMini) {
      @include sizer(90vw, 30vh);
    }

    @include breakpoint(max, $mobile) {
      @include sizer(90vw, 80vh);
    }

    .item {
      @include position(absolute, 0 0 0 0);
      @include sizer(100%);
      @include border-radius(10px);
      background: #cae9ff;
      overflow: hidden;

      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-around;

      @include breakpoint(max, $mobile) {
        flex-direction: column;
      }

      &::before {
        content: "";
        @include border-radius(50px);
        @include position(absolute, 50% 0 0 50%);
        @include sizer(98%, 95%);
        // background: #e0f2ff;
        background: #e2f2ff;
        transform: translate3d(-50%, -50%, 0);
        -webkit-transform: translate3d(-50%, -50%, 0);
        z-index: 0;
      }

      .content {
        margin: 0 4vw;
        z-index: 1000;
        position: relative;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);

        display: flex;
        flex-direction: column;

        @include breakpoint(max, $tablet) {
          margin: 0;
        }

        .index {
          border: 1px solid #0466c8;
          border-radius: 64px;
          color: #0466c8;
          font-family: AuxMono;
          font-size: 1.25rem;
          line-height: 100%;
          margin-bottom: 2vw;
          margin-right: auto;
          padding: 0.25rem 1.25rem;
          z-index: 10000;
        }

        .titleWrapper {
          $borderRadius: 64px;
          position: relative;
          display: inline-block;
          margin-bottom: 7vh;

          @include breakpoint(max, $tablet) {
            margin-bottom: 3vh;
          }

          .title {
            background: #ffffff;
            border: 1px solid #333333;
            border-radius: $borderRadius;
            color: #333333;
            display: inline-block;
            font-family: Helonik;
            font-size: 1.25vw;
            margin-right: auto;
            padding: 1rem 4rem;
            position: relative;
            transform-origin: 0 0;
            white-space: nowrap;

            @include breakpoint(max, $tablet) {
              font-size: 2.5vw;
              padding: 1.25rem 2rem;
            }
          }
        }

        .text {
          color: #333333;
          font-family: Helonik;
          font-size: 1.75vw;
          line-height: 140%;
          padding-left: 2vw;
          margin-bottom: 7vh;
          width: 30vw;
          @include antialias;

          @include breakpoint(max, $tablet) {
            font-size: 2.5vw;
            margin-bottom: 5vh;
            width: 45vw;
          }

          span {
            font-family: AuxMono;
            text-decoration: underline;
            letter-spacing: -0.2ch;
          }
        }

        .btnWrapper {
          display: inline-block;
          padding-left: 2vw;
        }
      }

      .videoWrapper {
        @include sizer(35vw);
        padding-left: 5vw;
        z-index: 100;

        @include breakpoint(max, $tablet) {
          padding-left: 2vw;
        }

        .video {
          @include sizer(100%);
          display: block;
        }
      }

      .linkWrapper {
        .icon {
          @include sizer(90%);
        }
      }
    }
  }

  .lottieWrapper {
    @include position(absolute, auto auto 0 50%);
    transform: translate(0%, -50%);

    @include breakpoint(max, $tablet) {
      @include position(absolute, auto auto 2.5vh 50%);
    }

    .lottie {
      @include sizer(1.5vw, auto);

      @include breakpoint(max, $tablet) {
        @include sizer(3vw, auto);
      }
    }
  }
}
