@import "gerillass";
@import "./variables";

.fieldsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;

  margin: 5vh auto;
  position: relative;
  width: 70vw;

  @include breakpoint(max, $tablet) {
    width: 90vw;
  }

  @include breakpoint(max, $mobile) {
    width: 90vw;
  }

  &::after {
    content: "";
    @include position(absolute, 0 0 0 0);
    @include sizer(100%);
    border: 1px solid #b9303f;
    opacity: 0.4;
  }

  .itemWrapper {
    padding: 7vh 5vw;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    @include breakpoint(max, $tablet) {
      padding: 3vh 4.5vw;
    }

    @include breakpoint(max, $mobile) {
      padding: 5vh 5vw;
    }

    &::after {
      content: "";
      @include position(absolute, 0 0 0 0);
      @include sizer(100%);
      border: 1px solid #b9303f;
      opacity: 0.4;
    }

    .iconWrapper {
      flex-shrink: 0;

      @include breakpoint(max, $mobile) {
        @include sizer(10vw);
      }

      .icon {
        @include sizer(100%);
        display: block;
        object-fit: contain;
      }
    }

    .text {
      color: #000000;
      font-family: AuxMono;
      font-size: 1.75vw;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.2ch;
      margin-left: 1rem;
      text-align: left;

      @include breakpoint(max, $tablet) {
        font-size: 3vw;
      }

      @include breakpoint(max, $mobile) {
        font-size: 5vw;
      }
    }
  }
}
